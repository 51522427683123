import React from "react";
import { routeCodes } from "config/routes";
import { connect } from "react-redux";
import { PLAN, SEO } from "constants/literals";
import { SCRIPTS } from "constants/embed";
import { addScript } from "helpers/index";
import SkipButton from "components/Buttons/SkipButton";
import AccordionSection from "components/AccordionSection";
import Hero from "components/Hero";
import HelmetWrapper from "components/HelmetWrapper";
import "./Plan.scss";

class Plan extends React.Component {
  componentDidMount() {
    addScript(SCRIPTS.GET_A_RIDE);
  }

  render() {
    const { copyParagraph } = this.props;
    return (
      <div className="Plan" role="main">
        <HelmetWrapper title={SEO.VOTING_PLAN} />
        <Hero title={PLAN.TITLE} text={copyParagraph.PARAGRAPH} />
        <div className="Plan-form-text-wrapper">
          <AccordionSection />
        </div>
        <div className="Plan-button-wrapper">
          <SkipButton to={routeCodes.SPREAD_THE_WORD} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    copyParagraph: state.sheetData.copyContent.PLAN.COPY,
  };
}

export default connect(mapStateToProps)(Plan);
