import React from "react";
import { ReactComponent as FacebookIcon } from "assets/svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "assets/svg/twitter.svg";
import { Header2 } from "components/Typography";
import "./FooterSocials.scss";

const FooterSocials = ({ label, socialsSheetData }) => {
  return (
    <div>
      {label && <Header2 className="FooterSocials-label">{label}</Header2>}
      <div className="Footer-socials-wrapper">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={socialsSheetData.FACEBOOK}
          className="Footer-social"
          aria-label="Go to woke-vote facebook"
        >
          <FacebookIcon />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className=" Footer-social Footer-instagram"
          href={socialsSheetData.INSTAGRAM}
          aria-label="Go to woke-vote instagram"
        >
          <InstagramIcon />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={socialsSheetData.TWITTER}
          className="Footer-social"
          aria-label="Go to woke-vote twitter"
        >
          <TwitterIcon />
        </a>
      </div>
    </div>
  );
};

export default FooterSocials;
