import React from "react";
import {
  getDateFromString,
  getFormattedDate,
  formatToIso,
} from "helpers/index";
import { routeCodes } from "config/routes";
import { EMBED_TYPES } from "constants/embed";
import { BUTTON } from "constants/index";
import { VOTE_ON_ELECTION } from "constants/literals";
import Button from "components/Buttons/Button";
import { BodyTextL } from "components/Typography";
import EmbedForm from "components/EmbedForm";
import "./VoteOnElectionDay.scss";

class VoteOnElectionDay extends React.Component {
  handleAddToCalendarClick = () => {
    const { stateData, sheetData } = this.props;

    const { ELECTION_DATE } = sheetData.copyContent.PLAN.API;

    const event = {
      title: VOTE_ON_ELECTION.CALENDAR_TITLE,
      startDatetime: formatToIso(
        new Date(getDateFromString(stateData[ELECTION_DATE].text))
      ),
      endDatetime: formatToIso(
        new Date(getDateFromString(stateData[ELECTION_DATE].text))
      ),
    };

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${event.startDatetime}/${event.endDatetime}&details=Find+more+details,+click+here:+http://www.wokevote.us&sf=true&output=xml`;
    window.open(url);
  };

  render() {
    const { stateData, sheetData } = this.props;
    const {
      ELECTION_DATE,
      ID_REQUIREMENTS_IN_PERSON,
    } = sheetData.copyContent.PLAN.API;

    const electionDate = getFormattedDate(
      getDateFromString(stateData[ELECTION_DATE].text)
    );

    const item = {
      title: VOTE_ON_ELECTION.ELECTION_DAY,
      date: electionDate,
    };

    return (
      <div className="VoteOnElectionDay">
        <div className="VoteOnElectionDay-dates">
          <BodyTextL className="VoteOnElectionDay-dates-title">
            {VOTE_ON_ELECTION.DATES}
          </BodyTextL>
          <div className="VoteOnElectionDay-date-item">
            <BodyTextL>{item.title}</BodyTextL>
            <BodyTextL>
              <b>{item.date}</b>
            </BodyTextL>
          </div>
        </div>
        {stateData[ID_REQUIREMENTS_IN_PERSON] &&
          stateData[ID_REQUIREMENTS_IN_PERSON].text !== "" && (
            <div className="VoteOnElectionDay-requirements">
              <BodyTextL className="VoteOnElectionDay-requirements-title">
                {VOTE_ON_ELECTION.REQUIREMENTS}
              </BodyTextL>
              <BodyTextL>{stateData[ID_REQUIREMENTS_IN_PERSON].text}</BodyTextL>
            </div>
          )}
        <div className="VoteOnElectionDay-buttons">
          <Button
            color={BUTTON.BLACK}
            to={routeCodes.POLLING_PLACE}
            label={VOTE_ON_ELECTION.CTA_1}
            className="VoteOnElectionDay-button"
          />
          <Button
            color={BUTTON.TRANSPARENT}
            handleClick={this.handleAddToCalendarClick}
            label={VOTE_ON_ELECTION.CTA_2}
            className="VoteOnElectionDay-button"
          />
        </div>
        <div className="VoteOnElectionDay-ride">
          <BodyTextL className="VoteOnElectionDay-ride-title">
            {VOTE_ON_ELECTION.SUBTITLE_1}
          </BodyTextL>
          <BodyTextL>{VOTE_ON_ELECTION.PARAGRAPH_1}</BodyTextL>
          <div className="VoteOnElectionDay-form">
            <EmbedForm type={EMBED_TYPES.GET_A_RIDE} />
          </div>
        </div>
      </div>
    );
  }
}

export default VoteOnElectionDay;
