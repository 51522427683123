import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routeCodes } from "config/routes";
import { setUserInfo, setIsStepCompleted } from "actions";
import { EMBED_TYPES } from "constants/embed";
import "./EmbedForm.scss";

class EmbedForm extends React.Component {
  observer;

  actionNetworkRef = React.createRef();

  state = {
    formData: {},
  };

  componentDidMount() {
    const { type } = this.props;
    /// If we bave action network form
    /// Connect to DOM Mutation observer, and observe changes to dom
    /// And we can complete COMMIT step in the flow.
    ///
    /// If it is not action network form, listen to custom "VoteAmericaEvent" listener

    const actionNetworkTypes = [
      EMBED_TYPES.COMMIT,
      EMBED_TYPES.GET_INVOLVED,
      EMBED_TYPES.GET_A_RIDE,
    ];

    const isActionNetworkForm = actionNetworkTypes.includes(type);

    if (isActionNetworkForm) {
      this.observer = new MutationObserver(this.observerCallBack);
      const config = { attributes: true, childList: true, subtree: true };
      this.observer.observe(this.actionNetworkRef.current, config);
    } else {
      window.addEventListener("VoteAmericaEvent", this.handleVoteAmericaEvent);
    }
  }

  observerCallBack = (mutationsList) => {
    const { dispatch, history, type, userInfo } = this.props;
    const { formData } = this.state;
    /// List of input id's we listen change to
    const INPUT_IDS = [
      "form-first_name",
      "form-last_name",
      "form-email",
      "form-country",
      "form-phone",
      "form-zip_code",
    ];

    mutationsList.map((nodes) => {
      if (type === EMBED_TYPES.COMMIT) {
        INPUT_IDS.map((id) => {
          if (nodes.target.id === id && nodes.target.value !== "") {
            const name = nodes.target.id.replace("form-", ""); /// form-first_name -> first_name

            /// On each input update, update local component state with key:values
            this.setState({
              formData: {
                ...formData,
                [name]: nodes.target.value,
              },
            });
          }
          return "";
        });
        /// Observe nodes, and try to find added node with name "commit"
        /// This means input has been clicked and data has been submitted.
        nodes.addedNodes.forEach((node) => {
          if (node.name === "commit") {
            dispatch(setUserInfo(formData));
            dispatch(setIsStepCompleted("commit"));
            history.replace(`${routeCodes.CONFIRMATION}/commit`);
          }
        });
      } else if (
        type === EMBED_TYPES.GET_INVOLVED ||
        type === EMBED_TYPES.GET_A_RIDE
      ) {
        /// If we already have data from Redux
        /// Add data to embeded inputs and hide inputs from page
        if (userInfo.first_name !== null) {
          INPUT_IDS.map((id) => {
            const formattedId = id.replace("form-", ""); /// form-first_name -> first_name
            const inputElement = document.getElementById(id);
            if (inputElement) {
              inputElement.value = userInfo[formattedId];
              inputElement.parentElement.style.display = "none";
            }
            return "";
          });
        }
        if (type === EMBED_TYPES.GET_INVOLVED) {
          nodes.addedNodes.forEach((node) => {
            if (node.name === "commit") {
              dispatch(setIsStepCompleted("get-involved"));
              history.replace(`${routeCodes.CONFIRMATION}/get-involved`);
            }
          });
        } else if (type === EMBED_TYPES.GET_A_RIDE) {
          nodes.addedNodes.forEach((node) => {
            if (node.name === "commit") {
              dispatch(setIsStepCompleted("get-a-ride"));
              history.replace(`${routeCodes.CONFIRMATION}/ride`);
            }
          });
        }
      }
      return "";
    });
  };

  handleVoteAmericaEvent = (event) => {
    const { isFormCompleted, type, dispatch } = this.props;
    if (isFormCompleted) {
      isFormCompleted(true);
    }

    if (type === EMBED_TYPES.REGISTER) {
      dispatch(setIsStepCompleted("register"));
    } else if (type === EMBED_TYPES.VERIFY) {
      dispatch(setIsStepCompleted("check"));
    }
  };

  componentWillUnmount() {
    const { type } = this.props;
    if (type === EMBED_TYPES.COMMIT) {
      this.observer && this.observer.disconnect();
    } else {
      window.removeEventListener(
        "VoteAmericaEvent",
        this.handleVoteAmericaEvent
      );
    }
  }

  render() {
    const { type } = this.props;

    const actionNetworkForms = [
      EMBED_TYPES.COMMIT,
      EMBED_TYPES.GET_INVOLVED,
      EMBED_TYPES.GET_A_RIDE,
    ];

    const isActionNetwork = actionNetworkForms.includes(type);

    const actionNetworkIds = {
      [EMBED_TYPES.COMMIT]: `can-form-area-${process.env.REACT_APP_EMBED_TYPES_COMMIT}`,
      [EMBED_TYPES.GET_INVOLVED]:
        `can-form-area-${process.env.REACT_APP_EMBED_TYPES_GET_INVOLVED}`,
      [EMBED_TYPES.GET_A_RIDE]: `can-form-area-${process.env.REACT_APP_EMBED_TYPES_GET_A_RIDE}`,
    };

    return isActionNetwork ? (
      <div id={actionNetworkIds[type]} ref={this.actionNetworkRef} />
    ) : (
      <div
        className="EmbedForm voteamerica-embed"
        data-subscriber="wokevote"
        data-tool={type}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

export default withRouter(connect(mapStateToProps)(EmbedForm));
