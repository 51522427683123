import {
  setStateData,
  setIsLoading,
  setError
} from "actions";
import {
  STATES
} from "mock";

const fetchStateData = (userState, sheetData, dispatch) => {
  const stateCode = STATES.find((state) => state.name === userState)
    .abbreviation;
  const URL = `https://api.voteamerica.com/v1/election/state/${stateCode}/`;

  /// Loop through all sheetData
  /// Find all fields which contain API
  /// Push fields values into keys array
  let keys = [];
  Object.values(sheetData)
    .filter((item) => item.API)
    .map((item) => item.API)
    .map((item) => keys.push(Object.values(item)));

  fetch(URL)
    .then((response) => response.json())
    .then((data) => {
      /// Reduce keys from array or arrays
      /// to array of unique items
      keys = keys
        .reduce((a, b) => a.concat(b), [])
        .filter((v, i, a) => a.indexOf(v) === i);
      const info = data.state_information;
      /// Check if combined data has all information we need
      const arrayOfCombinedData = info.filter((infoItem) =>
        keys.includes(infoItem.field_type)
      );
      const hasAllData = arrayOfCombinedData.length === keys.length;
      /// If it has - dispatch data and remove loader
      if (hasAllData) {
        dispatch(setStateData(data));
        dispatch(setIsLoading(false));
        /// If it has not - dispatch error
      } else {
        dispatch(setError(true));
      }
    })
    .catch((e) => dispatch(setError(true)));
};

export {
  fetchStateData
};