import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { routeCodes } from "config/routes";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import "./StickyHeader.scss";

const StickyHeader = ({ show }) => {
  const StickyHeaderClasses = cx({
    StickyHeader: true,
    "StickyHeader--show": show,
  });

  return (
    <Link
      aria-label="Go to home"
      className={StickyHeaderClasses}
      to={routeCodes.HOME}
    >
      <LogoIcon />
    </Link>
  );
};

export default StickyHeader;
