import React from "react";
import { getDateFromString, getFormattedDate } from "helpers/index";
import { routeCodes } from "config/routes";
import { BUTTON } from "constants/index";
import { VOTE_BY_MAIL } from "constants/literals";
import { BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./VoteByMail.scss";

class VoteByMail extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { stateData, sheetData } = this.props;
    const {
      BALLOT_RETURN_DEADLINE_BY_MAIL,
      BALLOT_RETURN_DEADLINE_IN_PERSON,
      REQUEST_DEADLINE_BY_IN_PERSON,
      REQUEST_DEADLINE_BY_MAIL,
      REQUEST_DEADLINE_ONLINE,
    } = sheetData.copyContent.PLAN.API;

    let data = [];

    const deadlineByMail = getFormattedDate(
      getDateFromString(stateData[BALLOT_RETURN_DEADLINE_BY_MAIL].text)
    );
    const deadlineInPerson = getFormattedDate(
      getDateFromString(stateData[BALLOT_RETURN_DEADLINE_IN_PERSON].text)
    );
    const VBMDeadlineInPerson = getFormattedDate(
      getDateFromString(stateData[REQUEST_DEADLINE_BY_IN_PERSON].text)
    );
    const VBMDeadlineByMail = getFormattedDate(
      getDateFromString(stateData[REQUEST_DEADLINE_BY_MAIL].text)
    );
    const VBMDeadlineOnline = getFormattedDate(
      getDateFromString(stateData[REQUEST_DEADLINE_ONLINE].text)
    );
    const allVBMDatesSame =
      VBMDeadlineInPerson === VBMDeadlineByMail &&
      VBMDeadlineInPerson === VBMDeadlineOnline &&
      VBMDeadlineOnline === VBMDeadlineByMail;

    if (allVBMDatesSame) {
      const item = {
        title: VOTE_BY_MAIL.REQUESTS.BALLOT,
        date: VBMDeadlineInPerson,
      };
      data.push(item);
    } else {
      let item;
      let secondaryItem;
      if (VBMDeadlineInPerson === VBMDeadlineByMail) {
        item = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_MAIL_PERSON,
          date: VBMDeadlineInPerson,
        };
        secondaryItem = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_ONLINE,
          date: VBMDeadlineOnline,
        };
      } else if (VBMDeadlineOnline === VBMDeadlineByMail) {
        item = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_MAIL_ONLINE,
          date: VBMDeadlineOnline,
        };
        secondaryItem = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_PERSON,
          date: VBMDeadlineInPerson,
        };
      } else if (VBMDeadlineOnline === VBMDeadlineInPerson) {
        item = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_PERSON_ONLINE,
          date: VBMDeadlineOnline,
        };
        secondaryItem = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_MAIL,
          date: VBMDeadlineByMail,
        };
      } else {
        const byMail = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_MAIL,
          date: VBMDeadlineByMail,
        };
        const online = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_ONLINE,
          date: VBMDeadlineOnline,
        };
        const inPerson = {
          title: VOTE_BY_MAIL.REQUESTS.BALLOT_PERSON,
          date: VBMDeadlineInPerson,
        };
        [byMail, online, inPerson].map((item) => data.push(item));
      }
      if (item) {
        data.push(item, secondaryItem);
      }
    }

    const itemByMail = {
      title: VOTE_BY_MAIL.REQUESTS.BALLOT_MAIL_INVERSE,
      date: deadlineByMail,
    };

    const itemInPerson = {
      title: VOTE_BY_MAIL.REQUESTS.BALLOT_DELIVER,
      date: deadlineInPerson,
    };

    data.push(itemByMail, itemInPerson);

    this.setState({
      data,
    });
  }

  render() {
    const { data } = this.state;
    const { stateData, sheetData } = this.props;

    const { VBM_WARNINGS } = sheetData.copyContent.PLAN.API;

    return (
      <div className="VoteByMail">
        {data.length > 0 && (
          <div className="VoteByMail-dates">
            <BodyTextL className="VoteByMail-dates-title">
              {VOTE_BY_MAIL.DEADLINES}
            </BodyTextL>
            {data
              .filter(
                (item) =>
                  !item.date.includes("NaN") || !item.date.includes("undefined")
              )
              .map((deadline) => (
                <div className="VoteByMail-deadline" key={deadline.title}>
                  <BodyTextL className="VoteByMail-deadline-title">
                    {deadline.title}
                  </BodyTextL>
                  <BodyTextL>
                    <b>{deadline.date}</b>
                  </BodyTextL>
                </div>
              ))}
          </div>
        )}
        {stateData[VBM_WARNINGS] && stateData[VBM_WARNINGS].text !== "" && (
          <div className="VoteByMail-requirements">
            <BodyTextL className="VoteByMail-requirements-title">
              {VOTE_BY_MAIL.REQUIREMENTS}
            </BodyTextL>
            <BodyTextL>{stateData[VBM_WARNINGS].text}</BodyTextL>
          </div>
        )}
        <Button
          color={BUTTON.BLACK}
          to={routeCodes.ABSENTEE_BALLOT}
          className="VoteByMail-CTA"
          label="Request absentee ballot"
        />
      </div>
    );
  }
}

export default VoteByMail;
