import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getDateFromString,
  getFormattedDate,
  isNaNOrUndefined,
} from "helpers/index";
import { routeCodes } from "config/routes";
import { REGISTER_STATUS, SEO } from "constants/literals";
import { BodyTextL } from "components/Typography";
import HelmetWrapper from "components/HelmetWrapper";
import Hero from "components/Hero";
import LinkStatus from "components/LinkStatus";
import SkipButton from "components/Buttons/SkipButton";
import "./Status.scss";

class Status extends React.Component {
  getFormattedLabel = (label, index) => {
    const labels = [
      REGISTER_STATUS.LABEL_1,
      REGISTER_STATUS.LABEL_2,
      REGISTER_STATUS.LABEL_3,
    ];
    return isNaNOrUndefined(label) ? "" : `${labels[index]} ${label}.`;
  };

  render() {
    const { sheetData, stateData } = this.props;
    const {
      REGISTRATION_DEADLINE_BY_MAIL,
      REGISTRATION_DEADLINE_ONLINE,
    } = sheetData.copyContent.STATUS.API;

    const { TITLE1 } = sheetData.copyContent.STATUS.COPY;

    let deadlineMailText =
      stateData && stateData[REGISTRATION_DEADLINE_BY_MAIL].text;
    let deadlineOnlineText =
      stateData && stateData[REGISTRATION_DEADLINE_ONLINE].text;

    const NO_VALUE = "N/A";
    const deadlineByMail =
      stateData && getFormattedDate(getDateFromString(deadlineMailText));
    const deadlineOnline =
      stateData && getFormattedDate(getDateFromString(deadlineOnlineText));

    let formattedLabel;

    if (stateData) {
      formattedLabel =
        deadlineByMail === deadlineOnline
          ? this.getFormattedLabel(deadlineByMail, 2)
          : this.getFormattedLabel(deadlineByMail, 0) +
            this.getFormattedLabel(deadlineOnline, 1);

      if (deadlineOnlineText === NO_VALUE) {
        formattedLabel = this.getFormattedLabel(deadlineByMail, 1);
      } else if (deadlineMailText === NO_VALUE) {
        formattedLabel = this.getFormattedLabel(deadlineOnline, 0);
      }
    }

    return (
      <div className="Status" role="main">
        <HelmetWrapper title={SEO.STATUS} />
        <Hero title={REGISTER_STATUS.TITLE} text={TITLE1} />
        <div className="Status-link-container">
          <div className="Status-link-wrapper">
            <BodyTextL className="Status-text">
              {REGISTER_STATUS.PARAGRAPH}
            </BodyTextL>
            <LinkStatus
              to={`${routeCodes.PREFORM}/register`}
              title={REGISTER_STATUS.OPTION_1_TITLE}
              label={formattedLabel}
              className="Status-link--hasBorder"
            />
            <LinkStatus
              to={`${routeCodes.PREFORM}/check`}
              title={REGISTER_STATUS.OPTION_2_TITLE}
              label={REGISTER_STATUS.OPTION_2_LABEL}
            />
          </div>
          <div className="Status-button-wrapper">
            <SkipButton to={routeCodes.PLAN} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stateData: state.stateData,
    sheetData: state.sheetData,
  };
}

export default withRouter(connect(mapStateToProps)(Status));
