import React from "react";
import { Header2 } from "components/Typography";
import ContactOrganizerBox from "components/ContactOrganizerBox";
import "./OrganizerSection.scss";

const OrganizerSection = ({ data }) => {
  return (
    <div className="OrganizerSection">
      {data.copy && (
        <Header2 className="OrganizerSection-title">
          {data.copy.CONTACT_CARD_TITLE}
        </Header2>
      )}
      {data.value && (
        <ContactOrganizerBox value={data.value} copy={data.copy} />
      )}
    </div>
  );
};

export default OrganizerSection;
