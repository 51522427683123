import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { electionDay } from "services/slider";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { WHITE_LOGO_ROUTES, routeCodes } from "config/routes";
import "./Logo.scss";

const Logo = ({ logoClassName, currentRoute, isErrorPage }) => {
  const isElectionOver = new Date() > electionDay;
  const logoClasses = cx({
    Logo: true,
    "Logo--white":
      WHITE_LOGO_ROUTES.includes(currentRoute) || isErrorPage || isElectionOver,
    "Logo--home": currentRoute === routeCodes.HOME && !isElectionOver,
    [`${logoClassName}`]: logoClassName,
  });

  const isIntroScreen = currentRoute === routeCodes.INTRO;

  return (
    <Link
      tabIndex={0}
      className={logoClasses}
      aria-label="Back to home"
      to={isIntroScreen ? routeCodes.INTRO : routeCodes.HOME}
    >
      <LogoIcon />
    </Link>
  );
};

export default Logo;
