const DAYS_COUNT = {
  RED: 10,
  YELLOW: 40,
  BLUE: 50,
};

const ELECTION_KEYS = [
  "2020_registration_deadline_by_mail",
  "2020_registration_deadline_in_person",
  "2020_registration_deadline_online",
  "2020_registration_changes",
  "2020_vbm_request_deadline_by_in_person",
  "2020_vbm_request_deadline_online",
  "2020_ballot_return_deadline_in_person",
  "2020_early_voting_starts",
  "2020_early_voting_ends",
  "2020_ballot_drop_date",
  "2020_ballot_return_deadline_by_mail",
  "2020_general_election_date",
  "2020_legal_changes",
  "2020_vbm_request_deadline_by_mail",
  "vbm_warnings",
  "id_requirements_sdr",
];

const CONFIRMATION_SLUG = {
  RIDE: "ride",
  VOTE_EARLY: "vote-early",
  GET_INVOLVED: "get-involved",
  COMMIT: "commit"
}

const PREFORM = {
  REGISTER: "register",
  CHECK: "check"
}

const BUTTON = {
  WHITE: 'white',
  BLACK: 'black',
  TRANSPARENT: 'transparent',
}

export {
  DAYS_COUNT,
  ELECTION_KEYS,
  CONFIRMATION_SLUG,
  PREFORM,
  BUTTON
};