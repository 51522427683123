import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactComponent as IntroArrowIcon } from "assets/svg/intro-select-arrow.svg";
import {
  INTRO_TITLE,
  INTRO_CTA,
  INTRO_PARAGRAPH,
  INTRO_DEFAULT_PLACEHOLDER,
} from "constants/literals";
import { BUTTON } from "constants/index";
import { STATES } from "mock/index";
import { routeCodes } from "config/routes";
import Dropdown from "components/Dropdown";
import Button from "components/Buttons/Button";
import { Header1, BodyTextS } from "components/Typography";
import "./IntroSection.scss";

class IntroSection extends React.Component {
  state = {
    showList: false,
  };

  handleClick = () => {
    const { history } = this.props;

    history.push(routeCodes.HOME);
  };

  handleList = () => {
    const { showList } = this.state;

    this.setState({
      showList: !showList,
    });
  };

  hideDropdownHandler = () => {
    this.setState({
      showList: false,
    });
  };

  render() {
    const { userState } = this.props;
    const { showList, county } = this.state;

    const introSelectClasses = cx({
      IntroSelect: true,
      "IntroSelect--changed": county && !showList,
    });

    const iconClasses = cx({
      "IntroSelect-icon": true,
      "IntroSelect-icon--transform": showList,
    });

    const introCountyClasses = cx({
      "Intro-county": true,
      "Intro-county--selected": userState,
    });

    const introSelectButtonClasses = cx({
      "IntroSelect-button": true,
      "IntroSelect-button--disabled": !userState,
    });

    const introSectionClasses = cx({
      IntroSection: true,
      "IntroSection--no-scroll": showList,
    });

    return (
      <div className={introSectionClasses}>
        <div className="IntroSection-textWrapper">
          <Header1 className="IntroSection-title">{INTRO_TITLE}</Header1>
          <BodyTextS className="IntroSection-label">
            {INTRO_PARAGRAPH}
          </BodyTextS>
        </div>
        <>
          <button className={introSelectClasses} onClick={this.handleList}>
            <div className="Intro-button-wrapper">
              <Header1 className={introCountyClasses}>
                {userState ? userState : INTRO_DEFAULT_PLACEHOLDER}
              </Header1>
              <IntroArrowIcon className={iconClasses} />
            </div>
          </button>
          {showList && (
            <Dropdown
              hideDropdown={this.hideDropdownHandler}
              isIntroScreen
              states={STATES}
            />
          )}
          <Button
            color={userState ? BUTTON.WHITE : ""}
            isDisabled={!userState}
            handleClick={this.handleClick}
            label={INTRO_CTA}
            className={introSelectButtonClasses}
          />
        </>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.state,
  };
}

export default withRouter(connect(mapStateToProps)(IntroSection));
