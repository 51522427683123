import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as LinkIcon } from "assets/svg/link.svg";
import { Header3, BodyTextS } from "components/Typography";
import "./Button.scss";

const Button = ({
  className,
  hasIcon,
  isDisabled,
  label,
  handleClick,
  to,
  isSmall,
  tabIndex,
  isMail,
  color,
  isBackToList,
}) => {
  const buttonClasses = cx({
    Button: true,
    [`${className}`]: className,
    [`Button--disabled`]: isDisabled,
    [`Button--${color}`]: color,
  });

  const buttonWrapperClasses = cx({
    "Button-wrapper": true,
    "Button-wrapper--inline": isBackToList,
  });

  const Component = to ? Link : "button";

  const content = (
    <div className={buttonWrapperClasses}>
      {isSmall ? (
        <BodyTextS className="Button-label">{label}</BodyTextS>
      ) : (
        <Header3 className="Button-label">{label}</Header3>
      )}
      {hasIcon && <LinkIcon className="Button-icon" />}
    </div>
  );

  return isMail ? (
    <a
      href={to}
      tabIndex={tabIndex}
      className={buttonClasses}
      disabled={isDisabled}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content}
    </a>
  ) : (
    <Component
      tabIndex={tabIndex}
      className={buttonClasses}
      to={to}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {content}
    </Component>
  );
};

export default Button;
