const getSteps = (data, literals, routeCodes, stepsCompleted) => {
  return data && data.length === 0
    ? [
        {
          text: literals.OPTION_1.TEXT,
          subtext: literals.OPTION_1.SUBTEXT,
          textCompleted: literals.OPTION_1.COMPLETED,
          isCompleted: stepsCompleted.includes("commit"),
          to: routeCodes.COMMIT,
        },
        {
          text: literals.OPTION_2.TEXT,
          subtext: literals.OPTION_2.SUBTEXT,
          textCompleted: literals.OPTION_2.COMPLETED,
          isCompleted:
            stepsCompleted.includes("register") &&
            stepsCompleted.includes("check"),
          to: routeCodes.STATUS,
        },
        {
          text: literals.OPTION_4.TEXT,
          subtext: literals.OPTION_4.SUBTEXT,
          textCompleted: literals.OPTION_4.COMPLETED,
          isCompleted: stepsCompleted.includes("get-involved"),
          to: routeCodes.SPREAD_THE_WORD,
        },
      ]
    : [
        {
          text: literals.OPTION_1.TEXT,
          subtext: literals.OPTION_1.SUBTEXT,
          textCompleted: literals.OPTION_1.COMPLETED,
          isCompleted: stepsCompleted.includes("commit"),
          to: routeCodes.COMMIT,
        },
        {
          text: literals.OPTION_2.TEXT,
          subtext: literals.OPTION_2.SUBTEXT,
          textCompleted: literals.OPTION_2.COMPLETED,
          isCompleted:
            stepsCompleted.includes("register") &&
            stepsCompleted.includes("check"),
          to: routeCodes.STATUS,
        },
        {
          text: literals.OPTION_3.TEXT,
          subtext: literals.OPTION_3.SUBTEXT,
          textCompleted: literals.OPTION_3.COMPLETED,
          isCompleted: false,
          to: routeCodes.PLAN,
        },
        {
          text: literals.OPTION_4.TEXT,
          subtext: literals.OPTION_4.SUBTEXT,
          textCompleted: literals.OPTION_4.COMPLETED,
          isCompleted: stepsCompleted.includes("get-involved"),
          to: routeCodes.SPREAD_THE_WORD,
        },
      ];
};

export { getSteps };
