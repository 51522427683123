import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { routeCodes } from "config/routes";
import { addScript } from "helpers/index";
import graphIcon from "assets/svg/graph.svg";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import { COMMIT_TITLE, SEO } from "constants/literals";
import Hero from "components/Hero";
import HelmetWrapper from "components/HelmetWrapper";
import SkipButton from "components/Buttons/SkipButton";
import EmbedForm from "components/EmbedForm";
import { BodyTextL } from "components/Typography";
import "./Commit.scss";

class Commit extends React.Component {
  componentDidMount() {
    const { userInfo, history } = this.props;

    const shouldShowForm = userInfo.first_name === null;

    /// Only add script if needed
    if (shouldShowForm) {
      addScript(SCRIPTS.COMMIT);
    } else {
      history.replace(`${routeCodes.CONFIRMATION}/commit`);
    }
  }

  render() {
    const { spreadSheetCopy } = this.props;

    const heroContent = [
      {
        number: spreadSheetCopy.PERCENTAGE,
        text: spreadSheetCopy.PERCENTAGE_STAT1,
      },
      {
        icon: graphIcon,
        text: spreadSheetCopy.PERCENTAGE_STAT2,
      },
    ];

    return (
      <div className="Commit" role="main">
        <HelmetWrapper title={SEO.COMMIT_TO_VOTE} />
        <Hero
          title={COMMIT_TITLE}
          text={spreadSheetCopy.SUBTITLE}
          heroContent={heroContent}
          isCommit
        />
        <div className="Commit-form-text-wrapper">
          <BodyTextL className="Commit-text">
            {spreadSheetCopy.SUBTITLE}
          </BodyTextL>
          <EmbedForm type={EMBED_TYPES.COMMIT} />
        </div>
        <div className="Commit-button-wrapper">
          <SkipButton to={routeCodes.STATUS} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    spreadSheetCopy: state.sheetData.copyContent.COMMIT.COPY,
  };
}

export default withRouter(connect(mapStateToProps)(Commit));
