import {
  getFormattedDate,
  getDateFromString
} from "helpers/index";


const getPlanContent = (data, sheet) => {
  const {
    REQUEST_DEADLINE_BY_IN_PERSON,
    REQUEST_DEADLINE_BY_MAIL,
    REQUEST_DEADLINE_ONLINE,
    EARLY_VOTING_STARTS,
    EARLY_VOTING_ENDS,
    ELECTION_DATE,
  } = sheet.PLAN.API;

  const accordions = [];

  const earlyVotingStarts = getDateFromString(data[EARLY_VOTING_STARTS].text);
  const earlyVotingEnds = getDateFromString(data[EARLY_VOTING_ENDS].text);
  const VBMDeadlineInPerson = getDateFromString(
    data[REQUEST_DEADLINE_BY_IN_PERSON].text
  );
  const VBMDeadlineByMail = getDateFromString(
    data[REQUEST_DEADLINE_BY_MAIL].text
  );
  const VBMDeadlineOnline = getDateFromString(
    data[REQUEST_DEADLINE_ONLINE].text
  );

  const hasEarlyVoteData =
    earlyVotingEnds !== null &&
    earlyVotingStarts !== "N/A" &&
    earlyVotingStarts !== null &&
    earlyVotingEnds !== "N/A";
  const hasVoteByMailData =
    VBMDeadlineByMail !== null ||
    VBMDeadlineInPerson !== null ||
    VBMDeadlineOnline !== null;

  if (hasVoteByMailData) {
    let item = {
      title: "Vote by mail",
      description: "Request ballot"
    };
    
    accordions.push(item);
  }

  if (hasEarlyVoteData) {
    const item = {
      title: "Vote early",
      description: `${getFormattedDate(earlyVotingStarts)} - ${getFormattedDate(
        earlyVotingEnds
      )}`,
    };
    accordions.push(item);
  }

  const electionDayItem = {
    title: "Vote on election day",
    description: `Get to your polling place ${getFormattedDate(
      getDateFromString(data[ELECTION_DATE].text)
    )}`,
  };

  accordions.push(electionDayItem);

  return accordions;
};

export {
  getPlanContent,
};