import React from "react";
import avatar from "assets/images/avatar.png";
import { BUTTON } from "constants/index";
import { BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./ContactOrganizerBox.scss";

const ContactOrganizerBox = ({
  copy,
  value: { REP_EMAIL, REP_NAME, REP_PHONE, REP_PHOTO },
}) => {
  const imgSrc = REP_PHOTO ? REP_PHOTO : avatar;

  return (
    <div className="ContactOrganizerBox">
      <div className="ContactOrganizerBox-image-wrapper">
        <img src={imgSrc} alt="Woke vote Representative" />
      </div>
      <BodyTextL className="ContactOrganizerBox-info">
        <b>{REP_NAME}</b>
      </BodyTextL>
      <div>
        <div>
          <a className="ContactOrganizerBox-link" href={"mailto:" + REP_EMAIL}>
            <BodyTextL className="ContactOrganizerBox-info">
              {REP_EMAIL}
            </BodyTextL>
          </a>
        </div>
        <div>
          {REP_PHONE && (
            <a className="ContactOrganizerBox-link" href={"tel:" + REP_PHONE}>
              <BodyTextL className="ContactOrganizerBox-info">
                {REP_PHONE}
              </BodyTextL>
            </a>
          )}
        </div>
      </div>
      <Button
        isMail
        color={BUTTON.TRANSPARENT}
        className="ContactOrganizerBox-button"
        label={copy.CONTACT_CARD_CTA}
        to={"mailto:" + REP_EMAIL}
      />
    </div>
  );
};

export default ContactOrganizerBox;
