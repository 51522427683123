import React from "react";
import cx from "classnames";
import { routeCodes } from "config/routes";
import { connect } from "react-redux";
import { addScript } from "helpers/index";
import { CHECK_REGISTRATION, SEO } from "constants/literals";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import HelmetWrapper from "components/HelmetWrapper";
import SkipButton from "components/Buttons/SkipButton";
import StepHeader from "components/StepHeader";
import { BodyTextL } from "components/Typography";
import Hero from "components/Hero";
import EmbedForm from "components/EmbedForm";
import "./CheckRegistration.scss";

class CheckRegistration extends React.Component {
  state = {
    isFormCompleted: false,
  };
  componentDidMount() {
    addScript(SCRIPTS.VOTE_AMERICA);
  }

  isFormCompletedHandler = (isCompleted) => {
    this.setState({
      isFormCompleted: isCompleted,
    });
  };

  render() {
    const { copyParagraph } = this.props;
    const { isFormCompleted } = this.state;

    const checkRegistrationClasses = cx({
      "CheckRegistration-form": true,
      "CheckRegistration-form--completed": isFormCompleted,
    });

    return (
      <div className="CheckRegistration" role="main">
        <HelmetWrapper title={SEO.CHECK_REGISTRATION} />
        {isFormCompleted ? (
          <StepHeader />
        ) : (
          <Hero
            title={CHECK_REGISTRATION.TITLE}
            text={CHECK_REGISTRATION.PARAGRAPH}
          />
        )}
        <div className={checkRegistrationClasses}>
          <BodyTextL className="CheckRegistration-form-copy">
            {copyParagraph}
          </BodyTextL>
          <EmbedForm
            type={EMBED_TYPES.VERIFY}
            isFormCompleted={this.isFormCompletedHandler}
          />
        </div>
        <div className="CheckRegistration-button-wrapper">
          <SkipButton to={routeCodes.PLAN} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    copyParagraph: state.sheetData.copyContent.REGISTER_CHECK.COPY.PARAGRAPH,
  };
}

export default connect(mapStateToProps)(CheckRegistration);
