import React from "react";
import cx from "classnames";
import { routeCodes } from "config/routes";
import { connect } from "react-redux";
import { addScript } from "helpers/index";
import { REGISTER_FORM, SEO } from "constants/literals";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import SkipButton from "components/Buttons/SkipButton";
import Hero from "components/Hero";
import { BodyTextL } from "components/Typography";
import EmbedForm from "components/EmbedForm";
import StepHeader from "components/StepHeader";
import HelmetWrapper from "components/HelmetWrapper";
import "./Register.scss";

class Register extends React.Component {
  state = {
    isFormCompleted: false,
  };

  componentDidMount() {
    addScript(SCRIPTS.VOTE_AMERICA);
  }

  isFormCompletedHandler = (isCompleted) => {
    this.setState({
      isFormCompleted: isCompleted,
    });
  };

  render() {
    const { isFormCompleted } = this.state;
    const { copyParagraph } = this.props;

    const registerFormClasses = cx({
      "Register-form": true,
      "Register-form--completed": isFormCompleted,
    });

    return (
      <div className="Register" role="main">
        <HelmetWrapper title={SEO.REGISTER_TO_VOTE} />
        {isFormCompleted ? (
          <StepHeader />
        ) : (
          <Hero
            title={REGISTER_FORM.TITLE}
            label={REGISTER_FORM.SUBTITLE}
            text={copyParagraph}
          />
        )}
        <div className={registerFormClasses}>
          <BodyTextL className="Register-form-copy">{copyParagraph}</BodyTextL>
          <EmbedForm
            isFormCompleted={this.isFormCompletedHandler}
            type={EMBED_TYPES.REGISTER}
          />
        </div>
        <div className="Register-button-wrapper">
          <SkipButton to={routeCodes.PLAN} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    copyParagraph: state.sheetData.copyContent.REGISTER_CHECK.COPY.PARAGRAPH,
  };
}

export default connect(mapStateToProps)(Register);
