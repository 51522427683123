import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { getPlanContent } from "services/plan";
import Accordion from "components/Accordion";
import VoteByMail from "components/VoteByMail";
import VoteEarly from "components/VoteEarly";
import VoteOnElectionDay from "components/VoteOnElectionDay";
import "./AccordionSection.scss";

class AccordionSection extends React.Component {
  state = {
    isOpened: false,
    accordions: null,
  };

  handleIsOpened = (isOpened) => {
    this.setState({
      isOpened,
    });
  };

  componentDidMount() {
    const { stateData, sheetData } = this.props;
    if (stateData) {
      this.setState({
        accordions: getPlanContent(stateData, sheetData.copyContent),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { stateData, sheetData } = this.props;
    if (stateData !== prevProps.stateData && stateData) {
      this.setState({
        accordions: getPlanContent(stateData, sheetData.copyContent),
      });
    }
  }

  render() {
    const { stateData, sheetData } = this.props;
    const { isOpened, accordions } = this.state;

    const accordionClasses = cx({
      Accordion: true,
      [`Accordion--opened`]: isOpened,
    });

    return accordions ? (
      <div className="AccordionSection">
        {accordions.map((row) => {
          const data = {
            "Vote by mail": (
              <VoteByMail stateData={stateData} sheetData={sheetData} />
            ),
            "Vote early": (
              <VoteEarly stateData={stateData} sheetData={sheetData} />
            ),
            "Vote on election day": (
              <VoteOnElectionDay stateData={stateData} sheetData={sheetData} />
            ),
          };
          return (
            <Accordion
              className={accordionClasses}
              key={row.title}
              title={row.title}
              description={row.description}
              isOpened={this.handleIsOpened}
            >
              {data[row.title]}
            </Accordion>
          );
        })}
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    stateData: state.stateData,
    sheetData: state.sheetData,
  };
}

export default connect(mapStateToProps)(AccordionSection);
