import React from "react";
import { connect } from "react-redux";
import { ReactComponent as FooterLogo } from "assets/svg/footer-logo.svg";
import { SOCIAL_TITLE } from "constants/literals";
import { Header2, BodyTextL } from "components/Typography";
import FooterSocials from "components/FooterSocials";
import "./Footer.scss";

const Footer = ({ data, socialsSheetData }) => {
  return data && data.copy ? (
    <div className="Footer">
      <div className="Footer-inner">
        <div className="Footer-text-wrapper">
          <Header2 className="Footer-title">{data.copy.ABOUT_TITLE}</Header2>
          <BodyTextL className="Footer-text">
            {data.copy.ABOUT_PARAGRAPH1}
          </BodyTextL>
          <BodyTextL className="Footer-text">
            {data.copy.ABOUT_PARAGRAPH2}
          </BodyTextL>
          <a
            className="Footer-link"
            rel="noopener noreferrer"
            target="_blank"
            href={socialsSheetData.WOKE_VOTE_SITE}
          >
            <BodyTextL className="Footer-link-text">{data.copy.LINK}</BodyTextL>
          </a>
          <div className="Footer-logo">
            <FooterLogo />
          </div>
        </div>
        <div className="Footer-tel-socials-wrapper">
          <div className="Footer-tel-wrapper">
            <Header2 className="Footer-title">
              {data.copy.CONTACT_TITLE}
            </Header2>
            {data.value.EMAIL && (
              <a
                href={`mailto:${data.value.EMAIL}`}
                className="Footer-email-link"
              >
                <BodyTextL className="Footer-email">
                  {data.value.EMAIL}
                </BodyTextL>
              </a>
            )}
            {data.value.PHONE && (
              <a
                href={`tel:+${data.value.PHONE}`}
                className="Footer-email-link"
              >
                <BodyTextL className="Footer-tel">{data.value.PHONE}</BodyTextL>
              </a>
            )}
          </div>
          <FooterSocials
            socialsSheetData={socialsSheetData}
            label={SOCIAL_TITLE}
          />
        </div>
      </div>
    </div>
  ) : null;
};

function mapStateToProps(state) {
  return {
    socialsSheetData: state.sheetData.copyContent.SOCIAL.CONTACT,
  };
}

export default connect(mapStateToProps)(Footer);
