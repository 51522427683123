import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as LinkArrowIcon } from "assets/svg/link-arrow.svg";
import { Header2, BodyTextL } from "components/Typography";
import "./LinkStatus.scss";

const LinkStatus = ({ title, label, to, className, state }) => {
  const linkClasses = cx({
    "LinkStatus-link": true,
    [`${className}`]: className,
  });
  return (
    <Link to={{ pathname: to, state: state }} className={linkClasses}>
      <div className="LinkStatus-inner">
        <div>
          <Header2 className="LinkStatus-title">{title}</Header2>
          <BodyTextL className="LinkStatus-label">{label}</BodyTextL>
        </div>
        <LinkArrowIcon />
      </div>
    </Link>
  );
};

export default LinkStatus;
