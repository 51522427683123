import React from "react";
import { withRouter } from "react-router-dom";
import { routeCodes } from "config/routes";
import { STEP_HEADER_LABEL, STEP_HEADER_LABEL_2  } from "constants/literals";
import Logo from "components/Logo";
import SkipButton from "components/Buttons/SkipButton";
import "./StepHeader.scss";

const StepHeader = ({ location }) => {
  let isCurrentRoute = location.pathname === routeCodes.POLLING_PLACE || location.pathname === routeCodes.ABSENTEE_BALLOT;

  return (
    <div className="StepHeader">
      <Logo currentRoute={location.pathname} logoClassName="StepHeader-logo" />
      <SkipButton
        to={isCurrentRoute ? routeCodes.SPREAD_THE_WORD : routeCodes.PLAN}
        buttonClassName="StepHeader-button"
        iconClassName="StepHeader-icon"
        textClassName="StepHeader-text"
        label={isCurrentRoute ? STEP_HEADER_LABEL_2 : STEP_HEADER_LABEL}
      />
    </div>
  );
};

export default withRouter(StepHeader);
