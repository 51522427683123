import {
  getDateFromString,
  getFormattedDate,
  isNaNOrUndefined,
} from "helpers/index";

const electionDay = new Date("2020-11-03");

const now = new Date();

const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getUTCDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getUTCDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const getSlides = (data, sheet) => {
  const {
    BALLOT_RETURN_DEADLINE_BY_MAIL,
    BALLOT_RETURN_DEADLINE_IN_PERSON,
    EARLY_VOTING_ENDS,
    EARLY_VOTING_STARTS,
    ELECTION_DATE,
    REGISTRATION_DEADLINE_BY_MAIL,
    REGISTRATION_DEADLINE_ONLINE,
    REGISTRATION_DEADLINE_IN_PERSON,
    REQUEST_DEADLINE_IN_PERSON,
    REQUEST_DEADLINE_BY_MAIL,
    REQUEST_DEADLINE_ONLINE,
  } = sheet;

  const deadlineByMail = getDateFromString(
    data[REGISTRATION_DEADLINE_BY_MAIL].text
  );
  const deadlineInPerson = getDateFromString(
    data[REGISTRATION_DEADLINE_IN_PERSON].text
  );
  const deadlineOnline = getDateFromString(
    data[REGISTRATION_DEADLINE_ONLINE].text
  );

  const VBMDeadlineInPerson = getDateFromString(
    data[REQUEST_DEADLINE_IN_PERSON].text
  );
  const VBMDeadlineByMail = getDateFromString(
    data[REQUEST_DEADLINE_BY_MAIL].text
  );
  const VBMDeadlineOnline = getDateFromString(
    data[REQUEST_DEADLINE_ONLINE].text
  );
  const ballotDeadlineMail = getDateFromString(
    data[BALLOT_RETURN_DEADLINE_BY_MAIL].text
  );
  const ballotDeadlineInPerson = getDateFromString(
    data[BALLOT_RETURN_DEADLINE_IN_PERSON].text
  );
  const electionDay = getDateFromString(data[ELECTION_DATE].text);
  const earlyVotingStarts = getDateFromString(data[EARLY_VOTING_STARTS].text);
  const earlyVotingEnds = getDateFromString(data[EARLY_VOTING_ENDS].text);

  const ballotDeadlinesAreSame = ballotDeadlineMail === ballotDeadlineInPerson;

  const initialItems = ballotDeadlinesAreSame ? [{
    label: "to return your voted ballot by mail or in person",
    count: dateDiffInDays(now, new Date(ballotDeadlineMail)),
  }, ] : [{
      label: "to return your voted ballot by mail",
      count: dateDiffInDays(now, new Date(ballotDeadlineMail)),
    },
    {
      label: "to return your voted ballot in person",
      count: dateDiffInDays(now, new Date(ballotDeadlineInPerson)),
    },
  ];

  const sliderData = [
    ...initialItems,
    {
      count: dateDiffInDays(now, new Date(earlyVotingStarts)),
      label: "until early voting begins",
    },
    {
      count: dateDiffInDays(now, new Date(earlyVotingEnds)),
      label: "until early voting ends",
    },
  ];
  const allRegistrationDatesSame =
    deadlineByMail === deadlineInPerson &&
    deadlineByMail === deadlineOnline &&
    deadlineInPerson === deadlineOnline;

  const allVBMDatesSame =
    VBMDeadlineInPerson === VBMDeadlineByMail &&
    VBMDeadlineInPerson === VBMDeadlineOnline &&
    VBMDeadlineOnline === VBMDeadlineByMail;

  if (allRegistrationDatesSame) {
    const item = {
      label: "left to register for General Election",
      count: dateDiffInDays(now, new Date(deadlineOnline)),
      date: deadlineOnline,
    };
    sliderData.push(item);
  } else if (deadlineInPerson === deadlineOnline) {
    const mainItem = {
      label: "left to register online or in person for General Election",
      count: dateDiffInDays(now, new Date(deadlineOnline)),
      date: deadlineOnline,
    };
    const secondaryItem = {
      label: "left to register by mail for General Election",
      count: dateDiffInDays(now, new Date(deadlineByMail)),
      date: deadlineByMail,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else if (deadlineByMail === deadlineInPerson) {
    const mainItem = {
      label: "left to register by mail or in person for General Election",
      count: dateDiffInDays(now, new Date(deadlineByMail)),
      date: deadlineByMail,
    };
    const secondaryItem = {
      label: "left to register online for General Election",
      count: dateDiffInDays(now, new Date(deadlineOnline)),
      date: deadlineOnline,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else if (deadlineByMail === deadlineOnline) {
    const mainItem = {
      label: "left to register online or by mail for General Election",
      count: dateDiffInDays(now, new Date(deadlineByMail)),
      date: deadlineByMail,
    };
    const secondaryItem = {
      label: "left to register in person for General Election",
      count: dateDiffInDays(now, new Date(deadlineInPerson)),
      date: deadlineInPerson,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else {
    const byMail = {
      label: "left to register by mail for General Election",
      count: dateDiffInDays(now, new Date(deadlineByMail)),
      date: deadlineByMail,
    };
    const online = {
      label: "left to register online for General Election",
      count: dateDiffInDays(now, new Date(deadlineOnline)),
      date: deadlineOnline,
    };
    const inPerson = {
      label: "left to register in person for General Election",
      count: dateDiffInDays(now, new Date(deadlineInPerson)),
      date: deadlineInPerson,
    };
    const items = [byMail, online, inPerson];
    items.filter((item) => {
      if (!isNaNOrUndefined(item.date)) {
        sliderData.push(item);
      }
      return "";
    });
  }

  if (allVBMDatesSame) {
    const item = {
      label: "left to request your absentee ballot",
      count: dateDiffInDays(now, new Date(VBMDeadlineInPerson)),
      date: VBMDeadlineInPerson,
    };
    sliderData.push(item);
  } else if (VBMDeadlineInPerson === VBMDeadlineOnline) {
    const mainItem = {
      label: "left to request your absentee ballot online or in person",
      count: dateDiffInDays(now, new Date(VBMDeadlineOnline)),
      date: VBMDeadlineOnline,
    };
    const secondaryItem = {
      label: "left to request your absentee ballot by mail",
      count: dateDiffInDays(now, new Date(VBMDeadlineByMail)),
      date: VBMDeadlineByMail,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else if (VBMDeadlineByMail === VBMDeadlineInPerson) {
    const mainItem = {
      label: "left to request your absentee ballot by mail or in person",
      count: dateDiffInDays(now, new Date(VBMDeadlineInPerson)),
      date: VBMDeadlineInPerson,
    };
    const secondaryItem = {
      label: "left to request your absentee ballot online",
      count: dateDiffInDays(now, new Date(VBMDeadlineOnline)),
      date: VBMDeadlineOnline,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else if (VBMDeadlineOnline === VBMDeadlineByMail) {
    const mainItem = {
      label: "left to request your absentee ballot online or by mail",
      count: dateDiffInDays(now, new Date(VBMDeadlineOnline)),
      date: VBMDeadlineOnline,
    };
    const secondaryItem = {
      label: "left to request your absentee ballot in person",
      count: dateDiffInDays(now, new Date(VBMDeadlineInPerson)),
      date: VBMDeadlineInPerson,
    };
    sliderData.push(secondaryItem);
    sliderData.push(mainItem);
  } else {
    const byMail = {
      label: "left to request your absentee ballot by mail",
      count: dateDiffInDays(now, new Date(VBMDeadlineByMail)),
      date: VBMDeadlineByMail,
    };
    const online = {
      label: "left to request your absentee ballot online",
      count: dateDiffInDays(now, new Date(VBMDeadlineOnline)),
      date: VBMDeadlineOnline,
    };
    const inPerson = {
      label: "left to request your absentee ballot in person",
      count: dateDiffInDays(now, new Date(VBMDeadlineInPerson)),
      date: VBMDeadlineInPerson,
    };
    const items = [byMail, online, inPerson];
    items.filter((item) => {
      if (!isNaNOrUndefined(item.date)) {
        sliderData.push(item);
      }
      return "";
    });
  }

  const electionDataItem = {
    count: dateDiffInDays(now, new Date(electionDay)),
    label: "until Election Day",
  };
  sliderData.push(electionDataItem);
  const filteredData = sliderData.filter((data) => {
    const isOnNov3rd =
      (data.label.includes("request") || data.label.includes("register")) &&
      getFormattedDate(getDateFromString(data.date)) === "Nov 3";
    return !isNaN(data.count) && data.count > 0 && !isOnNov3rd;
  });

  const sortedData = filteredData.sort((a, b) => a.count - b.count);

  return sortedData;
};

export {
  getSlides,
  dateDiffInDays,
  electionDay
};