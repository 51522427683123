const EMBED_TYPES = {
  REGISTER: "register",
  VERIFY: "verify",
  ABSENTEE: "absentee",
  COMMIT: "commit",
  GET_INVOLVED: "get_involved",
  LOCATE: "locate",
  GET_A_RIDE: "get_a_ride",
};

const SCRIPTS = {
  VOTE_AMERICA: "https://cdn.voteamerica.com/embed/tools.js",
  COMMIT:
    `https://actionnetwork.org/widgets/v3/form/${process.env.REACT_APP_EMBED_TYPES_COMMIT}?clear_id=true&format=js&source=widget`,
  GET_INVOLVED:
    `https://actionnetwork.org/widgets/v3/form/${process.env.REACT_APP_EMBED_TYPES_GET_INVOLVED}?clear_id=true&format=js&source=widget`,
  GET_A_RIDE:
    `https://actionnetwork.org/widgets/v3/form/${process.env.REACT_APP_EMBED_TYPES_GET_A_RIDE}?clear_id=true&format=js&source=widget`,
};

export { EMBED_TYPES, SCRIPTS };
