const localStorageFirstName = localStorage.getItem("woke_vote_user_first_name");
const localStorageLastName = localStorage.getItem("woke_vote_user_last_name");
const localStorageEmail = localStorage.getItem("woke_vote_user_email");
const localStorageCountry = localStorage.getItem("woke_vote_user_country");
const localStorageZip = localStorage.getItem("woke_vote_user_zip_code");
const localStoragePhone = localStorage.getItem("woke_vote_user_phone");
const localStorageCompletedSteps = localStorage.getItem(
  "woke_vote_completed_steps"
);

const localStorageSelectedState = localStorage.getItem(
  "woke_vote_selected_state"
);

const initialUserInfo = {
  first_name: localStorageFirstName,
  last_name: localStorageLastName,
  email: localStorageEmail,
  country: localStorageCountry,
  zip_code: localStorageZip,
  phone: localStoragePhone,
  isLoading: false,
  showError: false,
};

const localStorageStringToArray =
  localStorageCompletedSteps === null
    ? []
    : localStorageCompletedSteps.split(",");

const initialState = {
  stepsCompleted: localStorageStringToArray,
  userInfo: initialUserInfo,
  state: localStorageSelectedState ? localStorageSelectedState : "",
  stateData: null,
  sheetData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "STEP":
      const array = state.stepsCompleted;
      array.push(action.data);

      const string = array.toString();

      localStorage.setItem("woke_vote_completed_steps", string);

      return {
        ...state,
        stepsCompleted: array,
      };
    case "USER_INFO":
      /// Iterate over action.data keys and set localStorage items

      Object.keys(action.data).map((item) =>
        localStorage.setItem(`woke_vote_user_${item}`, action.data[item])
      );

      return {
        ...state,
        userInfo: action.data,
      };
    case "CLEAR_USER_INFO":
      const clearedUserInfo = {
        first_name: null,
        last_name: null,
        email: null,
        country: null,
        zip_code: null,
        phone: null,
      };

      /// Iterate over clearedUserInfo keys and remove localStorage items

      Object.keys(clearedUserInfo).map((item) =>
        localStorage.removeItem(`woke_vote_user_${item}`)
      );

      localStorage.removeItem("woke_vote_completed_steps");

      return {
        ...state,
        userInfo: clearedUserInfo,
        stepsCompleted: [],
      };
    case "CHOOSE_STATE":
      localStorage.setItem(`woke_vote_selected_state`, action.data);
      return {
        ...state,
        state: action.data,
      };
    case "LOADING":
      return {
        ...state,
        isLoading: action.data,
      };
    case "STATE_DATA":
      let electionData = null;

      if (action.data && action.data.state_information) {
        const informations = action.data.state_information;

        electionData = {};

        Object.values(informations).map(
          (information) => (electionData[information.field_type] = information)
        );
      }

      return {
        ...state,
        stateData: electionData,
      };
    case "SHEET_DATA":
      return {
        ...state,
        sheetData: action.data,
      };
    case "ERROR":
      return {
        ...state,
        showError: action.data,
      };
    default:
      return state;
  }
}
