import React from "react";
import cx from "classnames";
import { withWindow } from "react-window-decorators";
import { BREAKPOINTS } from "constants/breakpoints";
import StickyHeader from "components/StickyHeader";
import { Header1, BodyTextL, Header2 } from "components/Typography";
import "./Hero.scss";

class Hero extends React.Component {
  heroRef = React.createRef();

  state = {
    showStickyHeader: false,
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const heroHeight = this.heroRef.current.offsetHeight;
    const scrollPosition = window.pageYOffset;

    this.setState({
      showStickyHeader: scrollPosition >= heroHeight,
    });
  };

  render() {
    const {
      title,
      text,
      label,
      heroContent,
      breakpoint,
      isCommit,
    } = this.props;
    const { showStickyHeader } = this.state;

    const heroHeaderClasses = cx({
      "Hero-header": true,
      "Hero-header--commit": isCommit,
    });

    return (
      <>
        <div className="Hero" ref={this.heroRef}>
          <div className="Hero-header-container">
            <div className={heroHeaderClasses}>
              <Header1 className="Hero-header-title">{title}</Header1>
              <div>
                {heroContent &&
                  breakpoint === BREAKPOINTS.XL &&
                  heroContent.map((content) => (
                    <div className="Hero-header-content" key={content.text}>
                      {content.number && (
                        <BodyTextL className="Hero-header-content-number">
                          {content.number}
                        </BodyTextL>
                      )}
                      {content.icon && (
                        <img
                          className="Hero-header-content-icon"
                          src={content.icon}
                          alt=""
                        />
                      )}
                      {content.text && (
                        <BodyTextL className="Hero-header-content-text">
                          {content.text}
                        </BodyTextL>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {(text || label) && (
            <div className="Hero-content">
              {label && (
                <Header2 className="Hero-content-label">{label}</Header2>
              )}
              {text && (
                <BodyTextL className="Hero-content-text">{text}</BodyTextL>
              )}
            </div>
          )}
        </div>
        <StickyHeader show={showStickyHeader} />
      </>
    );
  }
}

export default withWindow(Hero);
