import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import DownloadBox from "components/DownloadBox";
import "./DownloadBoxModal.scss";

const DownloadBoxModal = ({ isOpened, sheetData }) => {
  const downloadBoxModalClasses = cx({
    DownloadBoxModal: true,
    "DownloadBoxModal--opened": isOpened,
  });

  return (
    <div className={downloadBoxModalClasses}>
      <DownloadBox
        isModal
        text={sheetData.copyContent.HOME.COPY.SHARE_PARAGRAPH}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sheetData: state.sheetData,
  };
}

export default connect(mapStateToProps)(DownloadBoxModal);
