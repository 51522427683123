import React from "react";
import { connect } from "react-redux";
import { withWindow, withScroll } from "react-window-decorators";
import { routeCodes } from "config/routes";
import { STATES } from "mock/index";
import { getSlides } from "services/slider";
import { getSteps } from "services/steps";
import { CHECKLIST, SEO } from "constants/literals";
import cx from "classnames";
import HelmetWrapper from "components/HelmetWrapper";
import Checklist from "components/Checklist";
import Dropdown from "components/Dropdown";
import CountDownSlider from "components/CountDownSlider";
import Footer from "components/Footer";
import ShareButton from "components/Buttons/ShareButton";
import DownloadBoxModal from "components/DownloadBoxModal";
import OrganizerSection from "components/OrganizerSection";
import AfterElectionHero from "components/AfterElectionHero";
import "./Home.scss";

class Home extends React.Component {
  state = {
    isDropdownOpen: false,
    isShareOpen: false,
    sliderData: null,
  };

  downloadBoxModalRef = React.createRef();
  shareButtonRef = React.createRef();

  componentDidMount() {
    const { stateData } = this.props;

    if (stateData) {
      this.updateSliderData();
    }

    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    const { isShareOpen } = this.state;
    if (
      !this.downloadBoxModalRef.current.contains(event.target) &&
      !this.shareButtonRef.current.contains(event.target) &&
      isShareOpen
    ) {
      this.toggleShareModal();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { stateData } = this.props;
    if (prevProps.stateData !== stateData) {
      this.updateSliderData();
    }
  }

  handleSliderCLick = () => {
    const { isShareOpen, isDropdownOpen } = this.state;

    if (isShareOpen) {
      this.toggleShareModal();
    }
    if (isDropdownOpen) {
      this.toggleDropdown();
    }
  };

  toggleShareModal = () => {
    const { isShareOpen, isDropdownOpen } = this.state;
    if (isDropdownOpen) {
      this.toggleDropdown();
    }
    this.setState({
      isShareOpen: !isShareOpen,
    });
  };

  toggleDropdown = () => {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  };

  updateSliderData = () => {
    const { stateData, sheetData } = this.props;

    const sliderData = getSlides(stateData, sheetData.copyContent.HOME.API);

    this.setState({
      sliderData,
    });
  };

  render() {
    const {
      stepsCompleted,
      userState,
      scrollPosition,
      breakpoint,
      sheetData,
    } = this.props;
    const { isShareOpen, sliderData, isDropdownOpen } = this.state;

    const checkList = getSteps(
      sliderData,
      CHECKLIST,
      routeCodes,
      stepsCompleted
    );

    const stateCode = STATES.find((state) => state.name === userState)
      .abbreviation;

    const footerSheetData = {
      copy: sheetData.copyContent.HOME.COPY,
      value: sheetData.copyContent.HOME.CONTACT,
    };

    const organizerData = {
      copy: sheetData.copyContent.HOME.COPY,
      value: sheetData.byStateContent[stateCode],
    };

    const afterElectionData = {
      title: sheetData.copyContent.HOME.COPY.AFTER_ELECTION_SLIDER_TITLE,
      description:
        sheetData.copyContent.HOME.COPY.AFTER_ELECTION_SLIDER_DESCRIPTION,
      cta: sheetData.copyContent.HOME.COPY.AFTER_ELECTION_SLIDER_CTA,
    };

    const dropdownClasses = cx({
      "Home-dropdown": true,
      "Home-dropdown--white": sliderData && sliderData.length === 0,
    });

    const sliderClasses = cx({
      "Home-slider": true,
      "Home-slider--white": sliderData && sliderData.length === 0,
    });

    return (
      <div role="main">
        <HelmetWrapper title={SEO.HOME} />
        <div ref={this.downloadBoxModalRef}>
          <DownloadBoxModal isOpened={isShareOpen} />
        </div>
        <div className={dropdownClasses}>
          <Dropdown
            isWhite={sliderData && sliderData.length === 0}
            toggleDropdown={this.toggleDropdown}
            isOpened={isDropdownOpen}
            scrollPosition={scrollPosition}
            selectedState={userState}
            states={STATES}
            breakpoint={breakpoint}
          />
        </div>
        <div className={sliderClasses}>
          {sliderData && sliderData.length > 0 && (
            <CountDownSlider
              slides={sliderData}
              handleSliderCLick={this.handleSliderCLick}
            />
          )}
          {sliderData && sliderData.length === 0 && (
            <AfterElectionHero
              handleSliderCLick={this.handleSliderCLick}
              data={afterElectionData}
            />
          )}
        </div>
        <div className="Home-middle-section">
          <Checklist list={checkList} />
          <div className="line" />
          <OrganizerSection data={organizerData} />
        </div>
        <Footer data={footerSheetData} />
        <div ref={this.shareButtonRef}>
          <ShareButton
            isTransparent={sliderData && sliderData.length === 0}
            onClick={this.toggleShareModal}
            isOpened={isShareOpen}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stepsCompleted: state.stepsCompleted,
    userState: state.state,
    stateData: state.stateData,
    sheetData: state.sheetData,
  };
}

export default withScroll(withWindow(connect(mapStateToProps)(Home)));
