import React from "react";
import { addScript } from "helpers/index";
import { SEO } from "constants/literals";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import EmbedForm from "components/EmbedForm";
import HelmetWrapper from "components/HelmetWrapper";
import StepHeader from "components/StepHeader";
import "./FindMyPollingPlace.scss";

class FindMyPollingPlace extends React.Component {
  componentDidMount() {
    addScript(SCRIPTS.VOTE_AMERICA);
  }

  render() {
    return (
      <div className="FindMyPollingPlace" role="main">
        <HelmetWrapper title={SEO.FIND_MY_POLLING_PLACE} />
        <StepHeader />
        <div className="FindMyPollingPlace-embed-form">
          <EmbedForm type={EMBED_TYPES.LOCATE} />
        </div>
      </div>
    );
  }
}

export default FindMyPollingPlace;
