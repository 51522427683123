import React from "react";
import {
  getDateFromString,
  getFormattedDate,
  formatToIso,
} from "helpers/index";
import { routeCodes } from "config/routes";
import { BUTTON } from "constants/index";
import { VOTE_EARLY } from "constants/literals";
import { BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./VoteEarly.scss";

class VoteEarly extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { stateData, sheetData } = this.props;
    const {
      EARLY_VOTING_STARTS,
      EARLY_VOTING_ENDS,
    } = sheetData.copyContent.PLAN.API;

    const earlyVotingStarts = getFormattedDate(
      getDateFromString(stateData[EARLY_VOTING_STARTS].text)
    );
    const earlyVotingEnds = getFormattedDate(
      getDateFromString(stateData[EARLY_VOTING_ENDS].text)
    );

    const data = [
      {
        title: VOTE_EARLY.BEGINS,
        date: earlyVotingStarts,
      },
      {
        title: VOTE_EARLY.ENDS,
        date: earlyVotingEnds,
      },
    ];

    this.setState({
      data,
    });
  }

  handleAddToCalendarClick = () => {
    const { stateData, sheetData } = this.props;

    const {
      EARLY_VOTING_STARTS,
      EARLY_VOTING_ENDS,
    } = sheetData.copyContent.PLAN.API;

    const event = {
      title: VOTE_EARLY.CALENDAR_TITLE,
      startDatetime: formatToIso(
        new Date(getDateFromString(stateData[EARLY_VOTING_STARTS].text))
      ),
      endDatetime: formatToIso(
        new Date(getDateFromString(stateData[EARLY_VOTING_ENDS].text))
      ),
    };

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${event.startDatetime}/${event.endDatetime}&details=Find+more+details,+click+here:+http://www.wokevote.us&sf=true&output=xml`;
    window.open(url);
  };

  render() {
    const { data } = this.state;
    const { stateData, sheetData } = this.props;
    const { EARLY_VOTING_WARNING } = sheetData.copyContent.PLAN.API;

    return (
      <div className="VoteEarly">
        {data.length > 0 && (
          <div className="VoteEarly-dates">
            <BodyTextL className="VoteEarly-dates-title">
              {VOTE_EARLY.DATES}
            </BodyTextL>
            {data.map((item) => (
              <div className="VoteEarly-date-item" key={item.title}>
                <BodyTextL>{item.title}</BodyTextL>
                <BodyTextL>
                  <b>{item.date}</b>
                </BodyTextL>
              </div>
            ))}
          </div>
        )}
        {stateData[EARLY_VOTING_WARNING] &&
          stateData[EARLY_VOTING_WARNING].text !== "" && (
            <div className="VoteEarly-requirements">
              <BodyTextL className="VoteEarly-requirements-title">
                {VOTE_EARLY.REQUIREMENTS}
              </BodyTextL>
              <BodyTextL>{stateData[EARLY_VOTING_WARNING].text}</BodyTextL>
            </div>
          )}
        <div className="VoteEarly-buttons">
          <Button
            color={BUTTON.BLACK}
            to={`${routeCodes.CONFIRMATION}/vote-early`}
            label={VOTE_EARLY.CTA_1}
            className="VoteEarly-button"
          />
          <Button
            color={BUTTON.TRANSPARENT}
            handleClick={this.handleAddToCalendarClick}
            label={VOTE_EARLY.CTA_2}
            className="VoteEarly-button"
          />
        </div>
      </div>
    );
  }
}

export default VoteEarly;
