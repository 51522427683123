import React from "react";
import cx from "classnames";
import { SHARE } from "constants/literals";
import { Header3 } from "components/Typography";
import "./ShareButton.scss";

const ShareButton = ({ onClick, isOpened, isTransparent }) => {
  const shareButtonClasses = cx({
    ShareButton: true,
    "ShareButton--opened": isOpened,
    "ShareButton--transparent": isTransparent,
  });

  return (
    <button className={shareButtonClasses} onClick={onClick}>
      <Header3 className="ShareButton-label">{SHARE}</Header3>
    </button>
  );
};

export default ShareButton;
