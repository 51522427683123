import React from "react";
import cx from "classnames";
import AnimateHeight from "react-animate-height";
import { ReactComponent as AccordionArrowIcon } from "assets/svg/accordion-arrow.svg";
import { Header2, BodyTextL } from "components/Typography";
import "./Accordion.scss";

export default class AccordionRow extends React.Component {
  state = {
    isExpanded: false,
  };

  toggle = () => {
    const { isExpanded } = this.state;
    const { isOpened } = this.props;

    this.setState({
      isExpanded: !isExpanded,
    });

    isOpened(true);
  };

  render() {
    const { isExpanded } = this.state;
    const { title, description, children, className } = this.props;

    const iconClasses = cx({
      Icon: true,
      "Icon--expanded": isExpanded,
    });

    const accordionClasses = cx({
      "Accordion-button": true,
      [`${className}`]: className,
    });

    return (
      <>
        <button onClick={this.toggle} className={accordionClasses}>
          <div className="Accordion-inner">
            <div>
              <Header2 className="Accordion-title">{title}</Header2>
              {!isExpanded && (
                <BodyTextL className="Accordion-description">
                  {description}
                </BodyTextL>
              )}
            </div>
            <AccordionArrowIcon className={iconClasses} />
          </div>
        </button>
        <div className="Accordion-rah">
          <AnimateHeight duration={500} height={isExpanded ? "auto" : 0}>
            {children}
          </AnimateHeight>
        </div>
      </>
    );
  }
}
