import * as React from "react";
import { Route, Switch } from "react-router-dom";
import Intro from "screens/Intro";
import Home from "screens/Home";
import Commit from "screens/Commit";
import Status from "screens/Status";
import Plan from "screens/Plan";
import SpreadTheWord from "screens/SpreadTheWord";
import Confirmation from "screens/Confirmation";
import Register from "screens/Register";
import CheckRegistration from "screens/CheckRegistration";
import PreForm from "screens/PreForm";
import RequestAbsenteeBallot from "screens/RequestAbsenteeBallot";
import FindMyPollingPlace from "screens/FindMyPollingPlace";
import Error from "screens/Error";
import data from "data/content.json";

const publicPath = "/";

export const routeCodes = {
  INTRO: `${publicPath}intro`,
  HOME: `${publicPath}home`,
  COMMIT: `${publicPath}commit-to-vote`,
  STATUS: `${publicPath}status`,
  REGISTER: `${publicPath}register-to-vote`,
  CHECK_REGISTRATION: `${publicPath}check-registration`,
  PLAN: `${publicPath}voting-plan`,
  SPREAD_THE_WORD: `${publicPath}spread-the-word`,
  CONFIRMATION: `${publicPath}confirmation`,
  PREFORM: `${publicPath}preform`,
  ABSENTEE_BALLOT: `${publicPath}request-absentee-ballot`,
  POLLING_PLACE: `${publicPath}find-polling-place`,
};

export const WHITE_LOGO_ROUTES = [
  routeCodes.INTRO,
  routeCodes.COMMIT,
  routeCodes.STATUS,
  routeCodes.REGISTER,
  routeCodes.CHECK_REGISTRATION,
  routeCodes.PLAN,
  routeCodes.SPREAD_THE_WORD,
  `${routeCodes.PREFORM}/register`,
  `${routeCodes.PREFORM}/check`,
  routeCodes.ABSENTEE_BALLOT,
  routeCodes.POLLING_PLACE,
];

export default () => {
  return (
    <Switch>
      <Route exact path={routeCodes.INTRO} component={Intro} />
      <Route exact path={routeCodes.HOME} component={Home} />
      <Route exact path={routeCodes.COMMIT} component={Commit} />
      <Route exact path={routeCodes.STATUS} component={Status} />
      <Route exact path={routeCodes.PLAN} component={Plan} />
      <Route
        exact
        path={routeCodes.SPREAD_THE_WORD}
        component={SpreadTheWord}
      />
      <Route
        path={routeCodes.CONFIRMATION + "/:page"}
        component={Confirmation}
      />
      <Route exact path={routeCodes.REGISTER} component={Register} />
      <Route
        exact
        path={routeCodes.CHECK_REGISTRATION}
        component={CheckRegistration}
      />
      <Route path={routeCodes.PREFORM + "/:page"} component={PreForm} />
      <Route
        exact
        path={routeCodes.ABSENTEE_BALLOT}
        component={RequestAbsenteeBallot}
      />
      <Route
        exact
        path={routeCodes.POLLING_PLACE}
        component={FindMyPollingPlace}
      />
      <Route
        render={() => <Error is404 data={data.copyContent.SOCIAL.CONTACT} />}
      />
    </Switch>
  );
};
