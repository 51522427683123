import React from "react";
import { ReactComponent as LoaderSvg } from "assets/svg/loading.svg";
import "./Loader.scss";

const Loader = () => (
  <div className="Loader">
    <LoaderSvg />
  </div>
);
export default Loader;
