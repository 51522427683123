import { DAY, DAYS } from "constants/literals";
import {STATES} from "../mock/index";

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const formatToIso = (date) => {
  return date.toISOString().replace(/[-:.]/g, "");
};

const getDateFromString = (string) => {
  return string.replace("received by ", "").replace("postmarked by ", "");
};

const getPluralizedDays = (count) => {
  return count > 1 ? DAYS : DAY;
};

const isNaNOrUndefined = (date) => {
  return date.includes("NaN") || date.includes("undefined");
};

const getFormattedDate = (date) => {
  const newDate = new Date(date);
  const month = shortMonths[newDate.getMonth()];
  const day = newDate.getUTCDate();
  return `${month} ${day}`;
};

const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

const extractStateFromUrl = (url) => {
  let stateFromUrlFormatted;

  const urlParams = url.split('/');
  const urlParamsCleaned = urlParams.filter(item => item !== "");
  const stateStringFromUrl = urlParamsCleaned[0];

  if(stateStringFromUrl) {
    // transform from kebab-case to a regular string with uppercase words
    stateFromUrlFormatted = stateStringFromUrl.split('-').map((item, i) => {
      return item[0].toUpperCase() + item.substring(1);
    }).join(' ');
  }

  return stateFromUrlFormatted;
}

const getStateData = (stateFromUrlFormatted) => {
  return STATES.filter(state => state.name === stateFromUrlFormatted)[0];
}

export {
  getDateFromString,
  getFormattedDate,
  formatToIso,
  addScript,
  getPluralizedDays,
  isNaNOrUndefined,
  extractStateFromUrl,
  getStateData,
};
