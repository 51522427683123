export function setIsStepCompleted(data) {
  return {
    type: "STEP",
    data,
  };
}

export function setUserInfo(data) {
  return {
    type: "USER_INFO",
    data,
  };
}

export function clearUserInfo(data) {
  return {
    type: "CLEAR_USER_INFO",
    data,
  };
}

export function chooseState(data) {
  return {
    type: "CHOOSE_STATE",
    data,
  };
}

export function setIsLoading(data) {
  return {
    type: "LOADING",
    data,
  };
}

export function setStateData(data) {
  return {
    type: "STATE_DATA",
    data,
  };
}

export function setSheetData(data) {
  return {
    type: "SHEET_DATA",
    data,
  };
}

export function setError(data) {
  return {
    type: "ERROR",
    data,
  };
}