import React from "react";
import { SEO } from "constants/literals";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import { addScript } from "helpers/index";
import EmbedForm from "components/EmbedForm";
import StepHeader from "components/StepHeader";
import HelmetWrapper from "components/HelmetWrapper";
import "./RequestAbsenteeBallot.scss";

class RequestAbsenteeBallot extends React.Component {
  componentDidMount() {
    addScript(SCRIPTS.VOTE_AMERICA);
  }

  render() {
    return (
      <div className="RequestAbsenteeBallot" role="main">
        <HelmetWrapper title={SEO.REQUEST_ABSENTEE_BALLOT} />
        <StepHeader />
        <div className="RequestAbsenteeBallot-embed-form">
          <EmbedForm type={EMBED_TYPES.ABSENTEE} />
        </div>
      </div>
    );
  }
}

export default RequestAbsenteeBallot;
