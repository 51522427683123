import React from "react";
import SwiperCore, { Pagination, A11y, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Countdown from "components/Countdown";

// swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

import "./CountDownSlider.scss";

SwiperCore.use([Pagination, A11y, Keyboard]);

class CountDownSlider extends React.Component {
  state = {
    activeSlideIndex: 0,
  };

  render() {
    const { slides, handleSliderCLick } = this.props;
    const { activeSlideIndex } = this.state;
    return (
      <Swiper
        keyboard={{
          enabled: true,
        }}
        onSlideChange={(event) => {
          this.setState({
            activeSlideIndex: event.activeIndex,
          });
        }}
        onTouchStart={() => {
          handleSliderCLick();
        }}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet: (index) => {
            return `<span class="dot swiper-pagination-bullet"></span>`;
          },
        }}
        scrollbar={{ draggable: true }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Countdown isActive={index === activeSlideIndex} slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
}

export default CountDownSlider;
