import React from "react";
import cx from "classnames";
import stayWoke from "assets/images/stay-woke.png";
import vote from "assets/images/vote.png";
import { SPREAD_THE_WORD } from "constants/literals";
import { BUTTON } from "constants/index";
import { Header2, BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./DownloadBox.scss";

class DownloadBox extends React.Component {
  state = {
    imageArray: [
      {
        name: "vote",
        url: vote,
      },
      {
        name: "stay-woke",
        url: stayWoke,
      },
    ],
  };
  fileDownloadHandler = async (pictures) => {
    const { imageArray } = this.state;
    for (var i = 0; i < pictures.length; i++) {
      let count = i;
      const response = await fetch(pictures[i]);
      response.blob().then((blob) => {
        let a = document.createElement("a");
        a.href = imageArray[count].url;
        a.download = `${imageArray[count].name}.png`;
        a.click();
      });
    }
  };
  render() {
    const { title, text, isModal } = this.props;
    const { imageArray } = this.state;

    const downloadBoxImageButtonWrapperClasses = cx({
      "DownloadBox-image-button-wrapper": true,
      "DownloadBox-image-button-wrapper--modal": isModal,
    });

    return (
      <>
        <div className="DownloadBox-involved-label-wrapper">
          {title && <Header2 className="DownloadBox-title">{title}</Header2>}
          {text && <BodyTextL className="DownloadBox-text">{text}</BodyTextL>}
        </div>
        <div className={downloadBoxImageButtonWrapperClasses}>
          <div className="DownloadBox-image-wrapper">
            <div className="Downloadbox-image-container">
              <img
                className="DownloadBox-image"
                src={stayWoke}
                alt="stay-woke-vote"
              />
            </div>
            <div className="Downloadbox-image-container">
              <img className="DownloadBox-image" src={vote} alt="vote" />
            </div>
          </div>
          <Button
            color={BUTTON.BLACK}
            className="DownloadBox-button"
            label={SPREAD_THE_WORD.CTA_1}
            handleClick={() => this.fileDownloadHandler(imageArray)}
          />
        </div>
      </>
    );
  }
}

export default DownloadBox;
