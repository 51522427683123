import React from "react";
import cx from "classnames";
import { routeCodes } from "config/routes";
import { getPluralizedDays } from "helpers/index";
import { DAYS_COUNT, BUTTON } from "constants/index";
import { HOME_CTA_RED, HOME_CTA } from "constants/literals";
import { Header1, BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./Countdown.scss";

const Countdown = ({ slide, isActive }) => {
  const { count, label } = slide;
  const backgroundClasses = cx({
    Countdown: true,
    "Countdown--red": count <= DAYS_COUNT.RED,
    "Countdown--yellow": count < DAYS_COUNT.YELLOW && count > DAYS_COUNT.RED,
    "Countdown--blue": count < DAYS_COUNT.BLUE && count > DAYS_COUNT.YELLOW,
  });

  const buttonText = count > DAYS_COUNT.RED ? HOME_CTA : HOME_CTA_RED;

  return (
    <div className={backgroundClasses}>
      <div className="Countdown-inner">
        <Header1 className="Countdown-title">
          {count} {getPluralizedDays(count)}
        </Header1>
        <BodyTextL className="Countdown-subtitle">{label}</BodyTextL>
        <Button
          color={BUTTON.BLACK}
          tabIndex={isActive ? 0 : -1}
          label={buttonText}
          to={routeCodes.COMMIT}
          className="Countdown-button"
        />
      </div>
    </div>
  );
};

export default Countdown;
