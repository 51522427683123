import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EMBED_TYPES, SCRIPTS } from "constants/embed";
import { SPREAD_THE_WORD, SEO } from "constants/literals";
import { routeCodes } from "config/routes";
import { addScript } from "helpers/index";
import Hero from "components/Hero";
import HelmetWrapper from "components/HelmetWrapper";
import EmbedForm from "components/EmbedForm";
import { Header2, BodyTextL } from "components/Typography";
import DownloadBox from "components/DownloadBox";
import "./SpreadTheWord.scss";

class SpreadTheWord extends React.Component {
  componentDidMount() {
    const { stepsCompleted, history } = this.props;
    if (stepsCompleted.includes("get-involved")) {
      history.replace(`${routeCodes.CONFIRMATION}/get-involved`);
    } else {
      addScript(SCRIPTS.GET_INVOLVED);
    }
  }

  render() {
    const { userInfo, spreadSheetData } = this.props;

    let text = spreadSheetData.PARAGRAPH2;
    let formattedString;

    if (userInfo && userInfo.first_name !== null) {
      formattedString = userInfo.first_name + ", " + text.toLowerCase();
    } else {
      formattedString = text;
    }

    return (
      <div className="SpreadTheWord" role="main">
        <HelmetWrapper title={SEO.SPREAD_THE_WORD} />
        <Hero
          title={SPREAD_THE_WORD.TITLE}
          label={spreadSheetData.TITLE1}
          text={spreadSheetData.PARAGRAPH1}
        />
        <div className="SpreadTheWord-socials-involved-wrapper">
          <div className="SpreadTheWord-socials-involved-inner-wrapper">
            <DownloadBox
              title={spreadSheetData.TITLE1}
              text={spreadSheetData.PARAGRAPH1}
            />
            <div className="SpreadTheWord-involved">
              <Header2 className="SpreadTheWord-involved-label">
                {spreadSheetData.TITLE2}
              </Header2>
              <BodyTextL className="SpreadTheWord-involved-name">
                {formattedString}
              </BodyTextL>
            </div>
            <div className="Spread-form">
              <EmbedForm type={EMBED_TYPES.GET_INVOLVED} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stepsCompleted: state.stepsCompleted,
    userInfo: state.userInfo,
    spreadSheetData: state.sheetData.copyContent.SPREAD_THE_WORD.COPY,
    socialsSheetData: state.sheetData.copyContent.SOCIAL.CONTACT,
  };
}

export default withRouter(connect(mapStateToProps)(SpreadTheWord));
