import React from "react";
import StepButton from "components/Buttons/StepButton";
import "./Checklist.scss";

const Checklist = ({ list }) => {
  return (
    <div className="Checklist-container">
      <div className="Checklist">
        {list.map((item) => (
          <StepButton
            key={item.text}
            text={item.text}
            textCompleted={item.textCompleted}
            subtext={item.subtext}
            isCompleted={item.isCompleted}
            to={item.to}
          />
        ))}
      </div>
      <div className="Checklist-line" />
    </div>
  );
};

export default Checklist;
