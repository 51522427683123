import * as React from "react";
import "./Typography.scss";

const createComponent = (defaultTagName, defaultProps) => {
  const TypographyComponent = ({ children, className, tagName, ...props }) => {
    const Tag = tagName ? tagName : "p";
    const tagClasses = className
      ? `${defaultProps.className} ${className}`
      : defaultProps.className;

    return (
      <Tag className={tagClasses} {...props}>
        {children}
      </Tag>
    );
  };

  TypographyComponent.defaultProps = {
    tagName: defaultTagName,
    className: "",
  };

  return TypographyComponent;
};

const LabelBig = createComponent("span", { className: "LabelBig" });

const Header1 = createComponent("h1", {
  className: "Header1",
});

const Header2 = createComponent("h2", {
  className: "Header2",
});

const Header3 = createComponent("h2", {
  className: "Header3",
});

const BodyTextL = createComponent("span", {
  className: "BodyTextL",
});

const BodyTextS = createComponent("span", {
  className: "BodyTextS",
});

const BodyTextSLink = createComponent("span", {
  className: "BodyTextS",
});

export {
  LabelBig,
  Header1,
  Header2,
  Header3,
  BodyTextL,
  BodyTextS,
  BodyTextSLink,
};
