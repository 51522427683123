import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { chooseState } from "actions";
import { BREAKPOINTS } from "constants/breakpoints";
import { CHOOSE_YOUR_STATE } from "constants/literals";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow.svg";
import { ReactComponent as ArrowUpIcon } from "assets/svg/arrow-up.svg";
import { Header1, BodyTextL } from "components/Typography";
import "./Dropdown.scss";

class Dropdown extends React.Component {
  state = {
    state: "",
  };

  dropdownRef = React.createRef();

  chooseState = (state) => {
    const {
      dispatch,
      hideDropdown,
      isIntroScreen,
      toggleDropdown,
    } = this.props;

    this.setState({
      state,
      showButton: true,
    });

    dispatch(chooseState(state));

    if (isIntroScreen) {
      hideDropdown();
    } else {
      toggleDropdown();
    }
  };

  handleList = () => {
    const { state } = this.state;
    const { dispatch, toggleDropdown, isIntroScreen } = this.props;

    if (isIntroScreen) {
      dispatch(chooseState(state));
    } else {
      toggleDropdown();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { scrollPosition, breakpoint, isOpened, toggleDropdown } = this.props;

    const isLargeBreakpoint = breakpoint === BREAKPOINTS.XL;

    if (scrollPosition !== prevProps.scrollPosition && scrollPosition !== 0) {
      if (isOpened && isLargeBreakpoint) {
        toggleDropdown();
      }
    }
  }

  render() {
    const {
      states,
      selectedState,
      isIntroScreen,
      isOpened,
      isWhite,
    } = this.props;
    const { state } = this.state;

    const dropdownClasses = cx({
      "Dropdown-wrapper": true,
      "Dropdown-wrapper--white": isWhite,
    });

    const dropDownListClasses = cx({
      "Dropdown-list": true,
      "Dropdown-list-homepage": !isIntroScreen,
    });

    const dropDownIconClasses = cx({
      "Dropdown-icon": true,
      "Dropdown-icon--opened": isOpened,
      "Dropdown-icon--white": isWhite,
    });

    return (
      <div ref={this.dropdownRef} className={dropdownClasses}>
        {!isIntroScreen && (
          <button className="Dropdown" onClick={this.handleList}>
            <div className="Dropdown-button-wrapper">
              <BodyTextL className="Dropdown-state">
                {state ? state : selectedState}
              </BodyTextL>
              <ArrowIcon className={dropDownIconClasses} />
            </div>
          </button>
        )}
        {(isOpened || isIntroScreen) && (
          <div className={dropDownListClasses}>
            <button className="Dropdown-list-header" onClick={this.handleList}>
              <BodyTextL className="Dropdown-list-header-text">
                {CHOOSE_YOUR_STATE}
              </BodyTextL>
              <ArrowUpIcon />
            </button>
            <div className="Dropdown-list-content">
              {states.map((state) => (
                <button
                  key={state.abbreviation}
                  className="Dropdown-list-item"
                  onClick={() => this.chooseState(state.name)}
                >
                  <Header1 className="Dropdown-list-item-text">
                    {state.name}
                  </Header1>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.state,
  };
}

export default withRouter(connect(mapStateToProps)(Dropdown));
