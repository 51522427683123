import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { ReactComponent as ButtonArrow } from "assets/svg/button-arrow.svg";
import { ReactComponent as ButtonArrowCompleted } from "assets/svg/button-arrow-completed.svg";
import { Header2, BodyTextL } from "components/Typography";
import "./StepButton.scss";

const StepButton = ({ text, subtext, textCompleted, isCompleted, to }) => {
  const buttonClasses = cx({
    StepButton: true,
    "StepButton--completed": isCompleted,
  });

  return (
    <Link className={buttonClasses} to={to}>
      <div className="StepButton-wrapper">
        <Header2 className="StepButton-title">
          {isCompleted ? textCompleted : text}
        </Header2>
        {!isCompleted && <BodyTextL>{subtext}</BodyTextL>}
      </div>
      <div className="StepButton-icon">
        {isCompleted ? <ButtonArrowCompleted /> : <ButtonArrow />}
      </div>
    </Link>
  );
};

export default StepButton;
