export const INTRO_INIT_TITLE = "Make your voice heard";
export const INTRO_INIT_PARAGRAPH =
  "We’ll help you get ready to vote in just a few steps.";
export const INTRO_TITLE = "What state do you live in?";
export const INTRO_PARAGRAPH =
  "Let us know your location so we can get you the right voting information.";
export const INTRO_DEFAULT_PLACEHOLDER = "Choose State";
export const INTRO_CTA = "Get info";
export const HOME_CTA_RED = "Act now";
export const HOME_CTA = "Get ready";
export const CHECKLIST = {
  OPTION_1: {
    TEXT: "Commit to vote",
    SUBTEXT: "Let’s do this together!",
    COMPLETED: "You’re committed",
  },
  OPTION_2: {
    TEXT: "Register or check status",
    SUBTEXT: "Be ready to vote.",
    COMPLETED: "You checked your registration",
  },
  OPTION_3: {
    TEXT: "Make a voting plan",
    SUBTEXT: "Know your options and deadlines.",
    COMPLETED: "You have a voting plan",
  },
  OPTION_4: {
    TEXT: "Spread the word",
    SUBTEXT: "Get your friends and family excited.",
    COMPLETED: "You joined the movement",
  },
};
export const SOCIAL_TITLE = "Follow us";
export const COMMIT_TITLE = "Commit to vote";
export const CONFIRMATION = {
  COMMIT: {
    TITLE: "Thanks for commiting, ",
    CTA: "Register or check my status",
    BACK: "Back to list",
  },
  GET_INVOLVED: {
    TITLE: "Thanks for being part of the movement",
    CTA: "Back to list",
  },
  VOTE_EARLY: {
    TITLE: "Voting early",
    PARAGRAPH_1:
      "You can vote in person before election day. Make sure to bring a non-expired photo ID.",
    SUBTITLE_2: "Where to go ",
    PARAGRAPH_2:
      "Find your polling location and hours for early voting on your state’s website.",
    CTA_1: "Check with my state",
    CTA_2: "Spread the word",
  },
  RIDE: {
    TITLE: "We’ll be in touch about your ride,",
    PARAGRAPH:
      "Woke Vote will reach out to our partners to find a ride for you on election day.",
    CTA_1: "Spread the word",
    CTA_2: "FInd my polling place",
  },
  RESET: "Start over",
};
export const REGISTER_STATUS = {
  TITLE: "Register or check status",
  OPTION_1_TITLE: "Register to vote",
  OPTION_2_TITLE: "Check your registration",
  OPTION_1_LABEL: "Last day to register online is ",
  OPTION_2_LABEL: "This only takes a minute.",
  LABEL_1: "Online by",
  LABEL_2: "By mail by",
  LABEL_3: "Online or by mail by",
};
export const REGISTER = {
  TITLE_1: "How to register to vote",
  OPTION_1: {
    SUBTITLE: "Register online by",
  },
  OPTION_2: {
    PARAGRAPH:
      "If you prefer, go to your state’s website, then download a registration form.",
    OPTION_1: "Return by mail:",
    OPTION_2: "Return in person:",
  },
  CTA_1: "Register online",
  CTA_2: "Make a voting plan",
  TITLE_2: "Check registration with your state",
  CTA_3: "Check registration",
  CTA_4: "Register to vote",
};
export const REGISTER_FORM = {
  TITLE: "Register or check status",
  SUBTITLE: "Register to vote",
};
export const CHECK_REGISTRATION = {
  TITLE: "Register or check status",
  PARAGRAPH:
    "Enter your information. Our partner, Vote America, will check your status.",
};
export const SPREAD_THE_WORD = {
  TITLE: "Spread the word",
  CTA_1: "Download Images",
};
export const PLAN = {
  TITLE: "Make a voting plan",
};
export const VOTE_BY_MAIL = {
  DEADLINES: "Deadlines",
  REQUIREMENTS: "Requirements",
  REQUESTS: {
    BALLOT: "Request ballot by",
    BALLOT_DELIVER: "Deliver ballot yourself by",
    BALLOT_MAIL_INVERSE: "Mail ballot by",
    BALLOT_MAIL: "Request ballot by mail by",
    BALLOT_MAIL_PERSON: "Request ballot by mail or in person by",
    BALLOT_MAIL_ONLINE: "Request ballot by mail or online by",
    BALLOT_ONLINE: "Request ballot online by",
    BALLOT_PERSON: "Request ballot in person by",
    BALLOT_PERSON_ONLINE: "Request ballot in person or online by",
  },
};
export const VOTE_EARLY = {
  CALENDAR_TITLE: "Vote Early - Act Now!",
  DATES: "Dates",
  BEGINS: "Early voting begins",
  ENDS: "Early voting ends",
  REQUIREMENTS: "Requirements",
  CTA_1: "How to vote early",
  CTA_2: "Add to calendar",
};
export const VOTE_ON_ELECTION = {
  CALENDAR_TITLE: "Vote today! It’s election day!",
  DATES: "Dates",
  ELECTION_DAY: "Election Day",
  REQUIREMENTS: "Requirements",
  CTA_1: "Find My polling place",
  CTA_2: "Add to calendar",
  SUBTITLE_1: "Need a ride on election day?",
  PARAGRAPH_1: "We can help you get to your polling place on election day.",
};
export const SKIP = "Skip";
export const SHARE = "Share website";
export const DAY = "day";
export const DAYS = "days";
export const CHOOSE_YOUR_STATE = "Choose your state:";
export const STEP_HEADER_LABEL = "Make a voting plan";
export const STEP_HEADER_LABEL_2 = "Spread the word";
export const SEO = {
  CHECK_REGISTRATION: "Woke vote | Check your registration",
  COMMIT_TO_VOTE: "Woke vote | Commit to vote",
  FIND_MY_POLLING_PLACE: "Woke vote | Find my polling place",
  HOME: "Woke vote | Home",
  INTRO: "Woke vote | Introduction",
  VOTING_PLAN: "Woke vote | Your voting plan",
  REGISTER_TO_VOTE: "Woke vote | Register to vote",
  REQUEST_ABSENTEE_BALLOT: "Woke vote | Request absentee ballot",
  SPREAD_THE_WORD: "Woke vote | Spread the word",
  STATUS: "Woke vote | Register or check your status",
  CONFIRMATION: "Woke vote",
  PREFORM: "Woke vote",
};

export const ERROR = {
  TITLE: "Oops! We need a minute.",
  TEXT:
    "We’re experiencing some technical issues retrieving information from your state. Please try again in a few minutes.",
  TITLE_404: "Page not found",
  CTA: "Go Back",
};

export const PREFORM_NO_ONLINE = {
  DESCRIPTION: `Online registration is not available in your state. You can still print & return a form. Go to your state's website to download a registration form`,
  CTA: "Visit state website",
  NORTH_DAKOTA_TITLE: "North Dakota does not register voters",
  NORTH_DAKOTA_DESCRIPTION: `If you are an eligible resident of North Dakota, you're already set to vote!`,
};
