import React from "react";
import { ERROR } from "constants/literals";
import Logo from "components/Logo";
import { Header1, Header3, BodyTextL } from "components/Typography";
import "./Error.scss";

const Error = ({ data, is404 }) => {
  return (
    <div className="Error">
      <Logo isErrorPage />
      <div className="Error-content">
        <Header1 className="Error-title">
          {is404 ? ERROR.TITLE_404 : ERROR.TITLE}
        </Header1>
        {!is404 && <BodyTextL className="Error-text">{ERROR.TEXT}</BodyTextL>}
        <a
          className="Error-button"
          rel={!is404 ? "noopener noreferrer" : null}
          target={!is404 ? "_blank" : null}
          href={is404 ? "/" : data.WOKE_VOTE_SITE}
        >
          <Header3>{ERROR.CTA}</Header3>
        </a>
      </div>
    </div>
  );
};

export default Error;
