const BREAKPOINTS = [
  {
    name: "xs",
    media: "(min-width: 0)",
  },
  {
    name: "sm",
    media: "(min-width: 375px)",
  },
  {
    name: "md",
    media: "(min-width: 768px)",
  },
  {
    name: "lg",
    media: "(min-width: 1024px)",
  },
  {
    name: "xl",
    media: "(min-width: 1400px)",
  },
];

export { BREAKPOINTS };
