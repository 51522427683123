import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HasTabbed from "has-tabbed";
import { setIsLoading, setSheetData, chooseState } from "actions";
import { fetchStateData } from "services/state";
import {extractStateFromUrl, getStateData} from "helpers";
import GA from "services/analytics";
import Routes, { routeCodes } from "config/routes";
import data from "data/content.json";
import Logo from "components/Logo";
import ScrollToTop from "components/ScrollToTop";
import Loader from "components/Loader";
import Error from "screens/Error";
import "App.scss";

export const tabbed = new HasTabbed();

class App extends Component {
  componentDidMount() {
    const { history, userState, location, dispatch } = this.props;

    // extract state name parameter from URL (if it exists), format it and get that state data from our list of states
    const stateFromUrlData = getStateData(extractStateFromUrl(location.pathname));

    // if user came from URL that contains state name parameter and that state name is valid,
    // skip intro screen and redirect user to home with state already selected
    if (stateFromUrlData) {
      dispatch(chooseState(stateFromUrlData.name));
      history.replace(routeCodes.HOME);
    }

    if (userState !== "" && location.pathname === "/") {
      history.replace(routeCodes.HOME);
    }

    if (userState === null || userState === "") {
      history.replace(routeCodes.INTRO);
    } else {
      fetchStateData(userState, data.copyContent, dispatch);
    }

    dispatch(setSheetData(data));

    tabbed.addEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userState, location, dispatch } = this.props;
    if (userState !== prevProps.userState && userState !== "") {
      if (location.pathname !== routeCodes.INTRO) {
        dispatch(setIsLoading(true));
      }
      fetchStateData(userState, data.copyContent, dispatch);
    }
  }

  componentWillUnmount() {
    tabbed.removeEvents();
  }

  render() {
    const { location, isLoading, sheetData, showError } = this.props;

    const showLoader = isLoading || !sheetData;

    if (showError) {
      return <Error data={sheetData.copyContent.SOCIAL.CONTACT} />;
    }

    return showLoader ? (
      <Loader />
    ) : (
      <div className="App">
        <GA.RouteTracker />
        <ScrollToTop />
        <div role="navigation">
          <Logo currentRoute={location.pathname} />
        </div>
        <Routes />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.state,
    sheetData: state.sheetData,
    isLoading: state.isLoading,
    showError: state.showError,
  };
}

export default withRouter(connect(mapStateToProps)(App));
