import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { SKIP } from "constants/literals";
import { ReactComponent as NextArrowIcon } from "assets/svg/next-arrow.svg";
import { BodyTextSLink } from "components/Typography";
import "./SkipButton.scss";

const SkipButton = ({
  to,
  buttonClassName,
  label,
  iconClassName,
  textClassName,
}) => {
  const skipButtonClasses = cx({
    SkipButton: true,
    [`${buttonClassName}`]: buttonClassName,
  });

  const skipIconClasses = cx({
    "SkipButton-icon": true,
    [`${iconClassName}`]: iconClassName,
  });

  const skipTextClasses = cx({
    "SkipButton-text": true,
    [`${textClassName}`]: textClassName,
  });

  return (
    <Link className={skipButtonClasses} to={to}>
      <div className="SkipButton-wrapper">
        <BodyTextSLink className={skipTextClasses}>
          {label ? label : SKIP}
        </BodyTextSLink>
        <NextArrowIcon className={skipIconClasses} />
      </div>
    </Link>
  );
};

export default SkipButton;
