import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { routeCodes } from "config/routes";
import { REGISTER, SEO, PREFORM_NO_ONLINE } from "constants/literals";
import { PREFORM, BUTTON } from "constants/index";
import {
  getDateFromString,
  getFormattedDate,
  isNaNOrUndefined,
} from "helpers/index";
import Button from "components/Buttons/Button";
import HelmetWrapper from "components/HelmetWrapper";
import { Header1, Header2, Header3, BodyTextL } from "components/Typography";
import "./PreForm.scss";

class PreForm extends React.Component {
  getPageContent = (page) => {
    const {
      contactSheetData,
      sheetData,
      stateData,
      preformSheetData,
    } = this.props;

    const {
      REGISTRATION_DEADLINE_BY_MAIL,
      REGISTRATION_DEADLINE_IN_PERSON,
      REGISTRATION_DEADLINE_ONLINE,
    } = sheetData.copyContent.STATUS.API;

    const deadlineOnline =
      stateData &&
      getFormattedDate(
        getDateFromString(stateData[REGISTRATION_DEADLINE_ONLINE].text)
      );

    const deadlineInPerson =
      stateData &&
      getFormattedDate(
        getDateFromString(stateData[REGISTRATION_DEADLINE_IN_PERSON].text)
      );

    const deadlineByMail =
      stateData &&
      getFormattedDate(
        getDateFromString(stateData[REGISTRATION_DEADLINE_BY_MAIL].text)
      );

    const isOnlineForbidden =
      deadlineOnline && isNaNOrUndefined(deadlineOnline);
    const isMailForbidden = deadlineByMail && isNaNOrUndefined(deadlineByMail);
    const isPersonForbidden =
      deadlineInPerson && isNaNOrUndefined(deadlineInPerson);

    const SOS_WEBSITE = stateData && stateData["sos_election_website"].text;

    const isEverythingForbidden =
      isOnlineForbidden && isMailForbidden && isPersonForbidden;

    const registerTitle = isEverythingForbidden
      ? PREFORM_NO_ONLINE.NORTH_DAKOTA_TITLE
      : isOnlineForbidden
      ? ""
      : `${REGISTER.OPTION_1.SUBTITLE} ${deadlineOnline}`;

    const registerDescription = isEverythingForbidden
      ? PREFORM_NO_ONLINE.NORTH_DAKOTA_DESCRIPTION
      : isOnlineForbidden
      ? PREFORM_NO_ONLINE.DESCRIPTION
      : preformSheetData.register.COPY.PARAGRAPH1;

    const pageContent = {
      register: {
        title: REGISTER.TITLE_1,
        body: (
          <div className="PreForm-body">
            <Header2 className="Preform-subtitle">{registerTitle}</Header2>
            <BodyTextL className="PreForm-description">
              {registerDescription}
            </BodyTextL>
            {!isOnlineForbidden && (
              <>
                <Header2 className="Preform-subtitle">
                  {preformSheetData.register.COPY.SUBTITLE2}
                </Header2>
                <BodyTextL className="PreForm-description">
                  {REGISTER.OPTION_2.PARAGRAPH}
                </BodyTextL>
                <ul className="Preform-list">
                  {deadlineByMail && (
                    <BodyTextL tagName="li">
                      {REGISTER.OPTION_2.OPTION_1} {deadlineByMail}
                    </BodyTextL>
                  )}
                  {deadlineInPerson && (
                    <BodyTextL tagName="li">
                      {REGISTER.OPTION_2.OPTION_2} {deadlineInPerson}
                    </BodyTextL>
                  )}
                </ul>
              </>
            )}
          </div>
        ),
        CTAs: [
          {
            isWhite: true,
            label: isOnlineForbidden ? PREFORM_NO_ONLINE.CTA : REGISTER.CTA_1,
            to: isOnlineForbidden ? SOS_WEBSITE : routeCodes.REGISTER,
            isMail: isOnlineForbidden,
          },
          {
            isWhite: false,
            label: REGISTER.CTA_2,
            to: routeCodes.PLAN,
          },
        ],
      },
      check: {
        title: REGISTER.TITLE_2,
        body: (
          <div className="PreForm-body">
            <BodyTextL className="PreForm-description">
              {preformSheetData.check.COPY.PARAGRAPH1}
            </BodyTextL>
          </div>
        ),
        CTAs: [
          {
            isWhite: true,
            label: REGISTER.CTA_3,
            to: routeCodes.CHECK_REGISTRATION,
          },
          {
            isWhite: false,
            label: REGISTER.CTA_4,
            to: routeCodes.REGISTER,
          },
        ],
      },
      footer: {
        mailto: "mailto:" + contactSheetData.EMAIL,
        tel: "tel:" + contactSheetData.PHONE,
      },
    };

    const preformSheetName =
      page === PREFORM.REGISTER ? PREFORM.REGISTER : PREFORM.CHECK;

    return (
      <div className="PreForm-content">
        <div className="PreForm-title-container">
          <Header1 className="PreForm-title">{pageContent[page].title}</Header1>
        </div>
        <div className="PreForm-cta-body-wrapper">
          <div className="PreForm-cta-body-inner-wrapper">
            {pageContent[page].body}
            <div className="Preform-CTAs">
              {pageContent[page].CTAs.map((cta) => (
                <Button
                  color={cta.isWhite ? BUTTON.WHITE : BUTTON.BLACK}
                  label={cta.label}
                  isMail={cta.isMail}
                  to={cta.to}
                  key={cta.label}
                  className={cx({
                    "Preform-CTA": true,
                    "Preform-CTA--white-border": !cta.isWhite,
                  })}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="Preform-footer">
          <div className="Preform-footer-text-wrapper">
            <Header3 className="Preform-footer-title">
              {preformSheetData[preformSheetName].COPY.PARAGRAPH2}
            </Header3>
            <Header3 className="Preform-footer-title">
              {preformSheetData[preformSheetName].COPY.PARAGRAPH3}
            </Header3>
          </div>
          <div>
            <a href={pageContent.footer.mailto} className="Preform-footer-link">
              <BodyTextL className="Preform-footer-email">
                {contactSheetData.EMAIL}
              </BodyTextL>
            </a>
            <a href={pageContent.footer.tel} className="Preform-footer-link">
              <BodyTextL className="Preform-footer-tel">
                {contactSheetData.PHONE}
              </BodyTextL>
            </a>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      match: {
        params: { page },
      },
    } = this.props;
    const preFormPages = [PREFORM.REGISTER, PREFORM.CHECK];
    return (
      <div className="PreForm" role="main">
        <HelmetWrapper title={SEO.PREFORM} />
        {preFormPages.includes(page) ? (
          this.getPageContent(page)
        ) : (
          <Redirect to="/404" />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    stepsCompleted: state.stepsCompleted,
    preformSheetData: {
      register: state.sheetData.copyContent.PREFORM_REGISTER,
      check: state.sheetData.copyContent.REGISTER_CHECK,
    },
    contactSheetData: state.sheetData.copyContent.HOME.CONTACT,
    stateData: state.stateData,
    sheetData: state.sheetData,
  };
}

export default withRouter(connect(mapStateToProps)(PreForm));
