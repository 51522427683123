import React from "react";
import { routeCodes } from "config/routes";
import { BUTTON } from "constants/index";
import { Header1, BodyTextL } from "components/Typography";
import Button from "components/Buttons/Button";
import "./AfterElectionHero.scss";

const AfterElectionHero = ({
  data: { title, description, cta },
  handleSliderCLick,
}) => {
  return (
    <div className="AfterElectionHero" onClick={handleSliderCLick}>
      <div className="AfterElectionHero-inner">
        <Header1 className="AfterElectionHero-title">{title}</Header1>
        <BodyTextL className="AfterElectionHero-subtitle">
          {description}
        </BodyTextL>
        <Button
          color={BUTTON.WHITE}
          label={cta}
          to={routeCodes.COMMIT}
          className="AfterElectionHero-button"
        />
      </div>
    </div>
  );
};

export default AfterElectionHero;
