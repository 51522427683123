import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { withWindow } from "react-window-decorators";
import { clearUserInfo } from "actions";
import { STATES } from "mock/index";
import { routeCodes } from "config/routes";
import { BREAKPOINTS } from "constants/breakpoints";
import { CONFIRMATION, SEO } from "constants/literals";
import { CONFIRMATION_SLUG, BUTTON } from "constants/index";
import HelmetWrapper from "components/HelmetWrapper";
import Button from "components/Buttons/Button";
import { Header1, Header3, BodyTextL, BodyTextS } from "components/Typography";
import "./Confirmation.scss";

class Confirmation extends React.Component {
  componentDidMount() {
    const { userInfo, history, location, stepsCompleted } = this.props;

    if (location.pathname.includes("commit") && !userInfo.first_name) {
      history.replace(routeCodes.COMMIT);
    } else if (
      location.pathname.includes("get-involved") &&
      !stepsCompleted.includes("get-involved")
    ) {
      history.replace(routeCodes.SPREAD_THE_WORD);
    }
  }

  /// If user wants to clear data we will dispatch redux action responsible for clearing local storage
  /// And redirect user to home screen
  clearLocalStorage = () => {
    const { dispatch, history } = this.props;
    history.replace(routeCodes.HOME);
    dispatch(clearUserInfo());
  };

  getContentInfo = () => {
    const { location, userInfo, sheetData, userState } = this.props;
    const stateCode = STATES.find((state) => state.name === userState)
      .abbreviation;
    const VOTE_EARLY_URL = sheetData.byStateContent[stateCode].TOOL;
    const {
      PARAGRAPH1,
      PARAGRAPH2,
      SUBTITLE1,
      SUBTITLE2,
    } = sheetData.copyContent.PLAN.COPY;
    let contentInfo = {};
    switch (location.pathname) {
      case `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.GET_INVOLVED}`:
        contentInfo = {
          label: CONFIRMATION.GET_INVOLVED.TITLE,
          url: routeCodes.HOME,
          buttonLabel: CONFIRMATION.GET_INVOLVED.CTA,
        };
        return contentInfo;
      case `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.COMMIT}`:
        contentInfo = {
          label: `${CONFIRMATION.COMMIT.TITLE} ${userInfo.first_name}!`,
          url: routeCodes.STATUS,
          secondUrl: routeCodes.HOME,
          buttonLabel: CONFIRMATION.COMMIT.CTA,
          secondButtonLabel: CONFIRMATION.COMMIT.BACK,
          isSecondButtonSmall: true,
        };
        return contentInfo;
      case `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.VOTE_EARLY}`:
        contentInfo = {
          description: [
            {
              title: SUBTITLE1,
              description: PARAGRAPH1,
            },
            {
              title: SUBTITLE2,
              description: PARAGRAPH2,
            },
          ],
          label: CONFIRMATION.VOTE_EARLY.TITLE,
          url: VOTE_EARLY_URL,
          icon: true,
          secondUrl: routeCodes.SPREAD_THE_WORD,
          buttonLabel: CONFIRMATION.VOTE_EARLY.CTA_1,
          secondButtonLabel: CONFIRMATION.VOTE_EARLY.CTA_2,
        };
        return contentInfo;
      case `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.RIDE}`:
        contentInfo = {
          description: [
            {
              description: CONFIRMATION.RIDE.PARAGRAPH,
            },
          ],
          label: `${CONFIRMATION.RIDE.TITLE} ${userInfo.first_name}`,
          url: routeCodes.SPREAD_THE_WORD,
          secondUrl: routeCodes.POLLING_PLACE,
          buttonLabel: CONFIRMATION.RIDE.CTA_1,
          secondButtonLabel: CONFIRMATION.RIDE.CTA_2,
        };
        return contentInfo;
      default:
        return contentInfo;
    }
  };

  render() {
    const { location, breakpoint } = this.props;

    const LOCATIONS = {
      ride: `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.RIDE}`,
      voteEarly: `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.VOTE_EARLY}`,
      commit: `${routeCodes.CONFIRMATION}/${CONFIRMATION_SLUG.COMMIT}`,
    };

    const secondButtonClasses = cx({
      "Confirmation-link": true,
      "Confirmation-link--black": this.getContentInfo().isSecondButtonSmall,
      "Confirmation-link--white": !this.getContentInfo().isSecondButtonSmall,
      "Confirmation-link--commit": location.pathname === LOCATIONS.commit,
    });

    const confirmationContentClasses = cx({
      "Confirmation-content": true,
      "Confirmation-content--not-centered":
        location.pathname === LOCATIONS.ride ||
        location.pathname === LOCATIONS.voteEarly,
    });

    const description = this.getContentInfo().description && (
      <div className="Confirmation-description-wrapper">
        {this.getContentInfo().description.map((item) => (
          <div className="Confirmation-description" key={item.description}>
            {item.title && (
              <Header3 className="Confirmation-description-title">
                {item.title}
              </Header3>
            )}
            <BodyTextL>{item.description}</BodyTextL>
          </div>
        ))}
      </div>
    );

    return this.getContentInfo().label ? (
      <div className="Confirmation" role="main">
        <HelmetWrapper title={SEO.CONFIRMATION} />
        <button
          className="Confirmation-header-button"
          onClick={this.clearLocalStorage}
        >
          <BodyTextS className="Confirmation-start-over">
            {CONFIRMATION.RESET}
          </BodyTextS>
        </button>
        <div className={confirmationContentClasses}>
          <div className="Confirmation-title-wrapper">
            <Header1 className="Confirmation-title">
              {this.getContentInfo().label}
            </Header1>
            {breakpoint !== BREAKPOINTS.XL && description}
          </div>
          <div className="Confirmation-button-wrapper">
            {breakpoint === BREAKPOINTS.XL && description}
            <div className="Confirmation-button-inner-wrapper">
              <Button
                color={BUTTON.BLACK}
                hasIcon={this.getContentInfo().icon}
                className="Confirmation-link"
                isMail={this.getContentInfo().icon}
                to={this.getContentInfo().url}
                label={this.getContentInfo().buttonLabel}
              />
              {this.getContentInfo().secondButtonLabel && (
                <Button
                  isBackToList={location.pathname === LOCATIONS.commit}
                  color={
                    location.pathname === LOCATIONS.commit
                      ? null
                      : BUTTON.TRANSPARENT
                  }
                  isSmall={this.getContentInfo().isSecondButtonSmall}
                  className={secondButtonClasses}
                  to={this.getContentInfo().secondUrl}
                  label={this.getContentInfo().secondButtonLabel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Redirect to="/404" />
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    sheetData: state.sheetData,
    userState: state.state,
    stepsCompleted: state.stepsCompleted,
  };
}

export default withRouter(withWindow(connect(mapStateToProps)(Confirmation)));
