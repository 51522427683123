import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  INTRO_INIT_TITLE,
  INTRO_INIT_PARAGRAPH,
  SEO,
} from "constants/literals";
import { routeCodes } from "config/routes";
import HelmetWrapper from "components/HelmetWrapper";
import { Header1, BodyTextL } from "components/Typography";
import IntroSection from "components/IntroSection";
import "./Intro.scss";

export const CHANGE_SECTION_TIME = 6000;

class Intro extends React.Component {
  state = {
    showInput: false,
  };

  changeSectionTimer = null;

  componentDidMount() {
    const { userState, history } = this.props;

    this.changeSectionTimer = setTimeout(() => {
      this.setState({
        showInput: true,
      });
    }, CHANGE_SECTION_TIME);

    if (userState) {
      history.push(routeCodes.HOME);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.changeSectionTimer);
  }

  render() {
    const { showInput } = this.state;
    const { userState } = this.props;

    const introTitleClasses = cx({
      "Intro-title": true,
      "Intro-title--hide": showInput,
    });

    const introSelectSectionClasses = cx({
      "Intro-select-section": true,
      "Intro-select-section-show": showInput,
      "Intro-select-section--noAnimation": userState,
    });

    return (
      <div className="Intro" role="main">
        <HelmetWrapper title={SEO.INTRO} />
        <div className={introTitleClasses}>
          <Header1>{INTRO_INIT_TITLE}</Header1>
          <BodyTextL className="Intro-text">{INTRO_INIT_PARAGRAPH}</BodyTextL>
        </div>
        {showInput && (
          <div className={introSelectSectionClasses}>
            <IntroSection />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.state,
  };
}

export default withRouter(connect(mapStateToProps)(Intro));
